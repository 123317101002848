<template>
  <div class="task-page" v-loading="pageLoading">
    <div
      class="task-record-container"
      v-if="taskDataList.length > 0 || checkSearchForm"
    >
      <div class="task-header-senior" v-if="ifShowSchedule">
        <div class="header">
          <div class="header-item policy-name">
            策略名称：
            <el-select
              v-model="searchForm.name"
              filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
              placeholder="请输入策略名称"
              clearable
            >
              <el-option
                v-for="item in scheduleOptions"
                :key="item.scheduleUid"
                :label="item.scheduleName"
                :value="item.scheduleUid"
              />
            </el-select>
          </div>
          <div class="header-item">
            任务ID：
            <el-select
              v-model="searchForm.taskUid"
              placeholder="请选择任务ID"
              clearable
              filterable
              @clear="handleClickClear"
            >
              <el-option
                v-for="item in taskIdOptions"
                :key="item.taskUid"
                :label="item.taskNumber"
                :value="item.taskUid"
              />
            </el-select>
          </div>
        </div>
        <div class="header">
          <div class="header-item create-time">
            创建时间：
            <el-date-picker
              :prefix-icon="customPrefix"
              v-model="searchForm.timeRanges"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
              @clear="handleClickClear"
            ></el-date-picker>
          </div>
          <div class="header-item">
            状态：
            <el-select
              v-model="searchForm.taskStatus"
              placeholder="请选择任务状态"
              clearable
              @clear="handleClickClear"
            >
              <el-option
                v-for="item in TASK_STATUS_OPTION"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <el-button type="primary" @click="getTaskDataList">查询</el-button>
          <el-button
            type="primary"
            plain
            class="button-plain"
            @click="handleClickReset"
          >
            清空
          </el-button>
        </div>
      </div>
      <div class="task-header" v-else>
        <div class="header-item">
          任务ID：
          <el-select
            v-model="searchForm.taskUid"
            placeholder="请选择任务ID"
            clearable
            filterable
            @clear="handleClickClear"
          >
            <el-option
              v-for="item in taskIdOptions"
              :key="item.taskUid"
              :label="item.taskNumber"
              :value="item.taskUid"
            />
          </el-select>
        </div>
        <div class="header-item">
          创建时间：
          <el-date-picker
            :prefix-icon="customPrefix"
            v-model="searchForm.timeRanges"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            clearable
            @clear="handleClickClear"
          />
        </div>
        <div class="header-item">
          状态：
          <el-select
            v-model="searchForm.taskStatus"
            placeholder="请选择任务状态"
            clearable
            @clear="handleClickClear"
          >
            <el-option
              v-for="item in TASK_STATUS_OPTION"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-button type="primary" @click="getTaskDataList">查询</el-button>
        <el-button @click="handleClickReset" class="button-plain">
          清空
        </el-button>
      </div>

      <div class="task-content">
        <div class="table-box list-table">
          <el-table
            :data="taskDataList"
            style="width: 100%"
            v-loading="tableLoading"
          >
            <el-table-column prop="taskNumber" label="任务ID" width="130px">
              <template #default="{ row }">
                <span>
                  {{ row.taskNumber }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="ifShowSchedule"
              prop="scheduleName"
              label="策略名称"
              min-width="180"
              show-overflow-tooltip
            />
            <el-table-column
              prop="startTime"
              label="创建时间"
              min-width="180px"
            >
              <template #default="scope">
                {{ timeFormatterDetail(scope.row.createTime) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="completeTime"
              label="完成时间"
              min-width="180"
            >
              <template #default="scope">
                {{ timeFormatterDetail(scope.row.completeTime) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="collectNum"
              label="采集数量"
              min-width="100px"
            >
              <template #default="scope">
                <span>{{ scope.row.collectNum }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              min-width="90px"
              fixed="right"
            >
              <template #default="{ row }">
                <el-tooltip
                  effect="light"
                  placement="top"
                  content="当前项目有任务排队中/进行中，策略生成任务失败"
                  v-if="row.status === TASK_STATUS_TYPE.FAILED"
                >
                  <TaskStatus :status="row.status"></TaskStatus>
                </el-tooltip>
                <TaskStatus :status="row.status" v-else></TaskStatus>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              width="120px"
              fixed="right"
            >
              <template #default="scope">
                <span class="edit option" @click="gotoData(scope.row)">
                  数据
                </span>
                <span
                  v-if="
                    scope.row.status == TASK_STATUS_TYPE.QUEUEING ||
                    scope.row.status == TASK_STATUS_TYPE.IN_PROGRESS
                  "
                  class="edit option"
                  @click="endTask(scope.row)"
                >
                  结束
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
            v-model:current-page="pageNum"
            v-model:page-size="pageSize"
            small
            background
            layout="total, prev, pager, next, sizes"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="goToPage"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div
      class="empty-container"
      v-if="!pageLoading && taskDataList.length === 0 && !checkSearchForm"
    >
      <EmptyStatus :size="350" :imageSrc="require('@/assets/imgs/empty2.png')">
        <template #description>
          <div>暂无任务</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  shallowRef,
  h,
  computed,
  onMounted,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import TaskStatus from "@/components/base/TaskStatus.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

import { Debounce } from "@/utils/utils";
import { timeFormatterDetail } from "@/utils/time.js";
import { usePagination } from "@/utils/usePagination";
import {
  TASK_STATUS_OPTION,
  TASK_STATUS_TYPE,
} from "@/constant/task_status.js";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import VueCookies from "vue-cookies";
import { ROLE, ROLE_MAP } from "@/constant/role.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";

const router = useRouter();
const route = useRoute();
// const ifShowSchedule = ref(false);
const role = ref(VueCookies.get("userInfo").spiderPermission?.role);
// TODO 测试用权限
// role.value = 3;
const ifShowSchedule = computed(() => {
  return role.value == ROLE.PREMIUM || role.value == ROLE.CUSTOMIZED;
});
const taskIdOptions = ref([]);
const scheduleOptions = ref([]);

//设置日期选择器图标
const customPrefix = shallowRef({
  render() {
    return h(
      "p",
      <el-icon>
        <Clock />
      </el-icon>
    );
  },
});
const projectId = ref(route.params.projectId);
const searchForm = ref({
  taskUid: null,
  timeRanges: [],
  name: "",
  taskStatus: "",
  scheduleUid: "",
});
//是否无筛选
const checkSearchForm = computed(() => {
  if (
    !searchForm.value.taskUid &&
    searchForm.value.name === "" &&
    searchForm.value.taskStatus === "" &&
    searchForm.value.timeRanges.length === 0
  ) {
    return false;
  } else {
    return true;
  }
});
//TODO-条件筛选待测试
// const handleClickSearch = Debounce(async () => {
//   let params = {
//     pageNum: pageNum.value,
//     pageSize: pageSize.value,
//     projectUids: [projectId.value],
//     taskUid: searchForm.value.taskUid,
//     beginTime: searchForm.value.timeRanges[0],
//     endTime: searchForm.value.timeRanges[1],
//     status: searchForm.value.taskStatus,
//   };
//   let res = await httpPost("/task/v1/searchTasksByConditions", params);
//   if (res.code == 0) {
//     taskDataList.value = res.data.data;
//     ElMessage.success("查询成功");
//   } else {
//     ElMessage.warning("查询失败");
//   }
// });
//清空
const handleClickReset = Debounce(() => {
  searchForm.value.taskUid = null;
  searchForm.value.name = "";
  searchForm.value.taskStatus = "";
  searchForm.value.timeRanges = [];
  getTaskDataList();
});
const handleClickClear = async () => {
  if (
    searchForm.value.taskUid == "" &&
    searchForm.value.name === "" &&
    searchForm.value.taskStatus === "" &&
    searchForm.value.timeRanges.length === 0
  ) {
    await getTaskDataList();
  }
};
const taskDataList = ref([]);
const tableLoading = ref(false);
const pageLoading = ref(true);
const getTaskDataList = async () => {
  pageLoading.value = true;
  tableLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    projectUids: [projectId.value],
    scheduleUids: [],
    taskUid: searchForm.value.taskUid,
    beginTime: searchForm.value.timeRanges[0],
    endTime: searchForm.value.timeRanges[1],
    status: searchForm.value.taskStatus,
  };
  let res = await httpPost("/task/v1/searchTasksByConditions", params);
  if (res.code == 0) {
    taskDataList.value = res.data.data;
    pageNum.value = res.data.pageNum;
    total.value = res.data.total;
    pageSize.value = res.data.pageSize;
    // taskIdOptions.value = taskDataList.value.map((item) => {
    //   return {
    //     label: item.taskNumber,
    //     value: item.taskUid,
    //   };
    // });
  }
  tableLoading.value = false;
  pageLoading.value = false;
};
//  获取策略列表
const getScheduleList = async () => {
  const res = await httpPost("/schedule/v1/getScheduleList");
  if (res.code == 0) {
    scheduleOptions.value = res.data;
  }
};
//获取项目所有任务uid
const getProjectTaskNumber = async () => {
  let params = {
    projectUid: projectId.value,
  };
  let res = await httpPost("/task/v1/searchProjectTaskNumber", params);
  if (res.code == 0) {
    taskIdOptions.value = res.data;
  }
};
//前往数据
const gotoData = (row) => {
  router.push({
    name: "ProjectData",
    params: {
      projectId: projectId.value,
    },
    query: {
      taskId: row.taskUid,
    },
  });
};
const endTask = (row) => {
  confirmDeleteBox("确认结束排队中的采集任务吗？").then(async () => {
    let params = {
      taskUid: row.taskUid,
    };
    let res = await httpPost("/collect/v1/finishCollectTask", params);
    if (res.code == 0) {
      ElMessage.success("结束任务指令发送成功");
    }
    getTaskDataList();
  });
};
onMounted(() => {
  if (ifShowSchedule.value) {
    getScheduleList();
  }
  getProjectTaskNumber();
});

const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getTaskDataList,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";

.task-page {
  min-height: $project-page-min-height;
}
.task-record-container {
  .task-header-senior {
    :deep(.el-range-editor) {
      width: 224px;
    }
    .header {
      display: flex;
      margin-bottom: 24px;
    }
    .header-item {
      margin-right: 24px;
      color: rgba(108, 108, 108, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
      :deep(.el-select) {
        width: 145px;
      }
    }
    .create-time {
      margin-right: 38px;
    }

    .policy-name {
      :deep(.el-select) {
        width: 224px;
      }
    }
    .policy-name:deep(.el-select .el-input__inner) {
      cursor: text;
    }
  }
  .task-header {
    display: flex;
    .button-plain {
      background-color: #ffffff;
      border-color: $theme-color;
      color: $theme-color;
    }
    :deep(.el-select) {
      width: 145px;
    }
    :deep(.el-range-editor) {
      width: 250px;
    }
    .header-item {
      margin-right: 24px;
      color: rgba(108, 108, 108, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
    }
  }
  .task-content {
    margin-top: 24px;
    .table-box {
      :deep(.el-table .el-table__cell) {
        // height: 48px;
      }
      .edit {
        color: #7f44f0;
      }
      .option {
        cursor: pointer;
      }
    }
    .pagination-box {
      display: flex;
      margin-top: 24px;
      justify-content: flex-end;
    }
  }
}
.empty-container {
  height: $project-page-min-height;
}
</style>
