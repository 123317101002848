<template>
  <div v-loading="loading" :style="{ 'min-height': loading ? '400px' : '' }">
    <el-row :gutter="30" v-show="data?.length > 0">
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
        v-for="item in data"
        :key="item"
      >
        <div class="card-box">
          <ScheduleCard :cardData="item" v-bind="$attrs"></ScheduleCard>
        </div>
      </el-col>
    </el-row>
    <div class="empty-container" v-show="!ifFirst && data.length == 0">
      <EmptyStatus :imageSrc="require('@/assets/imgs/empty2.png')" :size="300">
        <template #description>
          <slot name="empty">
            <div style="font-size: 18px; margin-bottom: 12px">
              暂无策略，立即
              <span
                class="description-button"
                @click="handleClickRelatedSchedule"
              >
                关联策略
              </span>
            </div>
            <div style="font-size: 16px">
              策略用来设置采集项目的执行时间，比如可以设置项目定时采集、循环采集等。
            </div>
          </slot>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch, ref, defineEmits } from "vue";
import ScheduleCard from "@/views/scheduleCenter/components/ScheduleCard.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["relate"]);
watch(
  () => props.loading,
  (newVal) => {
    console.log("watch iffirst", newVal);
    if (newVal == false) {
      ifFirst.value = false;
    }
  }
);
const ifFirst = ref(true);
const handleClickRelatedSchedule = () => {
  emits("relate");
};
</script>

<style lang="scss" scoped>
.card-box {
  margin-bottom: 32px;
}
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - $header-height - $right-wrapper-header-height);
}
</style>
