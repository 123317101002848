<template>
  <div>
    <ScheduleIndex />
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, inject } from "vue";
import { httpPost } from "@/api/httpService.js";
import { getBreadcrumbList } from "./api/breadcrumb.js";
// import ScheduleCardList from "./components/ScheduleCardList.vue";
import ScheduleIndex from "./components/ScheduleIndex.vue";

const projectId = inject("projectId");
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "策略",
      name: "ProjectSchedule",
      params: { projectId },
    },
  ];
  let result = await getBreadcrumbList(projectId, breadcrumbList);
  const pageTitle = "策略";
  emits("setBreadcrumbList", result);
  emits("setPageTitle", pageTitle);
};

setBreadcrumb();
</script>

<style lang="scss" scoped></style>
