<template>
  <div class="overview-card" v-loading="loading">
    <div class="header">
      <div class="title">
        <div
          class="content"
          style="color: red"
          v-showTip
          v-if="spiderInfo.isNeedUpdate"
        >
          <el-tooltip class="box-item" effect="light" placement="top-start">
            <template #content>
              该爬虫内容已变更，您可选
              <br />
              择
              <span class="upload-button" @click="handleClickResetSpider">
                撤销更新
              </span>
              或者
              <span class="upload-button" @click="handleClickPublishSpider">
                上线更新
              </span>
            </template>
            <span style="color: red" class="ellipse-text">
              {{ spiderInfo.spiderName }}
            </span>
          </el-tooltip>
        </div>

        <div class="content" v-showTip v-else>
          <el-tooltip placement="bottom-start" effect="light">
            <template #content>
              {{ spiderInfo.spiderName }}
            </template>
            <div class="title-text">
              <span class="ellipse-text">
                {{ spiderInfo.spiderName }}
              </span>
            </div>
          </el-tooltip>
        </div>
        <div class="status-button" v-if="spiderInfo.isBindingSpider">
          <span>
            <SpiderStatusButton
              v-if="spiderInfo.spiderStatus === SPIDER_STATUS.DRAFT"
              :status="spiderInfo.spiderStatus"
              @enable="handelClickEnableSpider"
              class="draft-tag"
            />
            <SpiderStatusTag
              v-else
              :status="spiderInfo.spiderStatus"
              class="status-tag"
            ></SpiderStatusTag>
            <!-- <i
              class="iconfont icon-kaishi"
              v-if="spiderInfo.spiderStatus === SPIDER_STATUS.DRAFT"
              @click="handelClickEnableSpider"
            /> -->
          </span>
        </div>
      </div>
      <div class="option" v-if="spiderInfo.spiderName">
        <i class="iconfont icon-edit" @click="goSpiderDesign" />
      </div>
    </div>
    <div class="main" v-if="spiderInfo.spiderName">
      <div class="main-info">
        <!-- <div class="info-type">
          <span>爬虫类型：</span>
          {{
            spiderInfo.spiderType
              ? SPIDER_TYPE_MAP[spiderInfo.spiderType].label
              : "--"
          }}
        </div> -->
        <div class="info-time" v-if="spiderInfo.spiderUpdateTime">
          <span>更新时间：</span>
          {{ timeFormatter(spiderInfo.spiderUpdateTime) }}
        </div>
        <div class="info-time" v-else>
          <span>创建时间：</span>
          {{ timeFormatter(spiderInfo.spiderCreateTime) }}
        </div>
      </div>
      <div class="main-desc">
        <span>爬虫描述：</span>
        {{ spiderInfo.spiderDescription ? spiderInfo.spiderDescription : "--" }}
      </div>
    </div>
    <div class="empty" v-if="!loading && !spiderInfo.isBindingSpider">
      <div class="empty-text">
        您还未配置爬虫，立即
        <span class="config-button" @click="handleClickConfigSpider">
          配置爬虫
        </span>
      </div>
      <div class="empty-tip">爬虫一旦配置，将无法更换，只能编辑</div>
    </div>
    <SpiderDialogConfig
      ref="spiderDialogConfigRef"
      width="60%"
      @submit="getSpiderInfo"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import { timeFormatter } from "@/utils/time.js";
import SpiderDialogConfig from "./SpiderDialogConfig.vue";
import { SPIDER_STATUS } from "@/constant/spider_status.js";
import SpiderStatusTag from "@/components/base/SpiderStatusTag.vue";
import { SPIDER_TYPE_MAP } from "@/constant/spider_type.js";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { remindErrorMessage } from "@/utils/utils";
import SpiderStatusButton from "@/components/base/SpiderStatusButton.vue";
const router = useRouter();
const route = useRoute();

const emits = defineEmits(["refresh"]);
const projectId = route.params.projectId;

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const spiderStatusName = ref("草稿");
const spiderInfo = ref({});
watch(
  () => props.data,
  (newVal) => {
    if (newVal.isBindingSpider) {
      spiderInfo.value = JSON.parse(JSON.stringify(newVal));
      spiderInfo.value.spiderName = spiderInfo.value.spiderName || "爬虫配置";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

//获取项目详情
const getSpiderInfo = async () => {
  emits("refresh");
};

//撤销爬虫更新
const handleClickResetSpider = async () => {
  let params = {
    projectUid: projectId,
  };
  let res = await httpPost("/projectSpider/v1/resetSpiderXMLDev", params);
  if (res.code == 0) {
    getSpiderInfo();
    ElMessage.success("撤销更新成功");
  } else {
    remindErrorMessage(res.message);
  }
};
// 检查爬虫是否需要升级
const checkIfRenewVersion = async () => {
  let params = {
    projectUid: projectId,
  };
  let res = await httpPost("/projectSpider/v1/isRenewSpiderVersion", params);
  return res;
};
const publishSpider = async (isRenewSpiderVersion) => {
  let params = {
    projectUid: projectId,
    isRenewSpiderVersion,
  };
  let res = await httpPost("/projectSpider/v1/publishSpiderXMLDev", params);
  if (res.code == 0) {
    getSpiderInfo();
    ElMessage.success("上线更新成功");
  } else {
    remindErrorMessage(res.message);
  }
};
//上线爬虫更新
const handleClickPublishSpider = async () => {
  let ifRenewVersionRes = await checkIfRenewVersion();
  if (ifRenewVersionRes.code != 0) {
    // 检查失败跳出
    return;
  }
  if (ifRenewVersionRes.data) {
    ElMessageBox.confirm("发现爬虫版本更新，是否需要更新", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      publishSpider(true);
    });
  } else {
    publishSpider(false);
  }
};

//启用爬虫
const handelClickEnableSpider = async () => {
  let params = {
    projectUid: projectId,
    // id: projectId,
    // isSpiderEnable: SPIDER_STATUS.ENABLED,
  };
  let res = await httpPost("/projectSpider/v1/enableSpider", params);
  if (res.code == 0) {
    getSpiderInfo();
    ElMessage.success("启用成功");
  }
};

//导入爬虫
const spiderDialogConfigRef = ref(null);
const handleClickConfigSpider = () => {
  spiderDialogConfigRef.value.openDLG({ id: projectId });
};

//前往爬虫设计器
const goSpiderDesign = () => {
  window.open(
    spiderDesignHost + `/spider/design/index.html?projectId=${projectId}`
  );
};
function handleReceiveMessage(event) {
  console.log("🚀 ~ handleReceiveMessage ~ event:", event);
  if (
    spiderDesignHost.indexOf(event.origin) != -1 &&
    event.data.name === "spider-design-refresh"
  ) {
    // console.log(event.data);
    // console.log(event.origin);
    // console.log(event.source);
    getSpiderInfo();
  }
}

onMounted(() => {
  //监听爬虫设计器的postMessage
  window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  window.removeEventListener("message", handleReceiveMessage);
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/overview-card.scss";
.status {
  .draft-tag {
    padding-left: 9px;
    padding-bottom: 10px;
  }
  .status-tag {
    font-weight: 500;
    margin-left: 20px;
    position: relative;
    top: -1px;
  }
  .iconfont {
    color: $theme-color;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
}
.upload-button {
  cursor: pointer;
  color: $theme-color;
  font-size: 12px;
}
.config-button {
  color: $theme-color;
  cursor: pointer;
}
</style>
