<template>
  <el-drawer
    v-model="DWRVisible"
    :before-close="closeDWR"
    :title="DWRTitle"
    custom-class="base-drawer"
  >
    <div class="variable-list">
      <div class="variable-item" v-for="item in dataList" :key="item.name">
        <div class="title">
          <i
            class="iconfont icon-wenben"
            v-if="
              item.type === FIELD_TYPE.STRING || item.type === FIELD_TYPE.TEXT
            "
          />
          <i
            class="iconfont icon-ruliriqixiao"
            v-if="item.type === FIELD_TYPE.DATETIME"
          />
          <i
            class="iconfont icon-number"
            v-if="
              item.type === FIELD_TYPE.INT || item.type === FIELD_TYPE.FLOAT
            "
          />
          <!-- <i
            class="iconfont icon-lianjie1"
            v-if="item.type === FIELD_TYPE.URL"
          /> -->
          <span>{{ item.label }}</span>
        </div>
        <div class="content">
          <div class="value-wrapper">
            <input
              type="checkbox"
              class="checkbox"
              :id="`checkbox-${item.name}`"
            />

            <div class="value" v-check-overflow="true">
              <label class="btn" :for="`checkbox-${item.name}`"></label>
              <!-- <a
                @click="goToUrl(dataInfo[item.name])"
                style="color: rgba(125, 101, 217, 1)"
                v-if="item.type === FIELD_TYPE.URL"
              >
                {{ dataInfo[item.name] }}
              </a> -->
              <span style="white-space: pre-line">
                {{ dataInfo[item.name] ? dataInfo[item.name] : "--" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import { FIELD_TYPE } from "@/constant/field_type.js";

const route = useRoute();

// #region 数据相关
const dataList = ref([]);
const dataInfo = ref({});
// #endregion 数据相关

// #region 抽屉相关
const DWRVisible = ref(false);
const DWRTitle = ref("数据详情");
const openDWR = (params) => {
  dataList.value = params.dataLabel;
  dataInfo.value = params.data;
  DWRVisible.value = true;
};
const closeDWR = () => {
  DWRVisible.value = false;
};
// #endregion 抽屉相关

const goToUrl = (url) => {
  window.open(url, "_blank");
};

defineExpose({ openDWR, closeDWR });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/base-drawer.scss";
.variable-list {
  padding: 0 10px;
  .variable-item {
    margin: 0 0 20px 0;
    .title {
      color: #000000;
      margin: 10px 0;
      .iconfont {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        margin-right: 10px;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
      }
    }
    .content {
      color: #6c6c6c;
      .value-wrapper {
        display: flex; // 辅助定位
        flex: 1;
        // 隐藏checkbox
        .checkbox {
          display: none;
        }
        // 勾选后使得value类展开
        .checkbox:checked + .value {
          -webkit-line-clamp: 999;
          max-height: none;
        }
        // 勾选后显示收起
        .checkbox:checked + .value .btn::after {
          content: "收起";
        }

        .checkbox:checked + .value .btn::before {
          visibility: hidden; /*在展开状态下隐藏省略号*/
        }
        .value {
          line-height: 20px; //行高
          // 超出指定行数隐藏
          display: -webkit-box;
          -webkit-line-clamp: 4; //最大行数
          -webkit-box-orient: vertical;
          overflow: hidden;
          // 展开/收起按钮
          .btn {
            float: right;
            clear: both;
            color: $theme-color;
            cursor: pointer;
          }
          .btn::after {
            content: "展开";
          }
          // 为文本占位

          &::before {
            content: "";
            float: right;
            height: calc(100% - 20px);
          }
        }
      }
    }
  }
}
</style>
