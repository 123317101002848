<template>
  <div class="spider-template-index">
    <DataStructureModuleDetail></DataStructureModuleDetail>
  </div>
</template>

<script setup>
import { reactive, ref, watch, defineEmits, onMounted } from "vue";
import { httpPost } from "@/api/httpService.js";
import DataStructureModuleDetail from "./components/DataStructureModuleDetail.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);
const moduleUid = ref("");
watch(
  () => route.params,
  (newVal, oldVal) => {
    if (newVal) {
      moduleUid.value = newVal.dataId;
    }
  },
  { immediate: true, deep: true }
);
const moduleName = ref("");
const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "爬虫模板",
      name: "SpiderTemplateIndex",
      params: {},
    },
    {
      title: "数据结构模板管理",
      name: "DataStructureModuleIndex",
      params: {},
    },
    {
      title: moduleName.value,
      params: {},
    },
  ];
  const pageTitle = moduleName.value;
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};
const getStructName = async () => {
  let res = await httpPost("/spiderDataStruct/v1/searchSpidersStructContent", {
    spiderStructUid: moduleUid.value,
  });
  if (res.code == 0) {
    moduleName.value = res.data.spidersStructName;
  }
};
onMounted(async () => {
  await getStructName();
  await setBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
