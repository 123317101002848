<template>
  <div class="document-view" v-loading="viewLoading">
    <div class="data-list" v-if="dataListTemp.length > 0">
      <div
        class="data-item"
        v-for="(dataItem, dataIndex) in dataListTemp"
        :key="dataIndex"
      >
        <div class="checkbox">
          <el-checkbox
            v-model="selectedIndexList[dataIndex]"
            @change="handleSelectData"
          ></el-checkbox>
        </div>
        <div class="content-box" :style="[dataIndex == 0 ? 'border:0' : '']">
          <div
            class="content-item"
            v-for="attrItem in dataColumn"
            :key="attrItem.name"
          >
            <div class="title">
              {{ attrItem.label }}
            </div>
            <div class="value-wrapper">
              <input
                type="checkbox"
                class="checkbox"
                :id="`checkbox-${dataIndex}-${attrItem.name}`"
              />

              <div class="value" v-check-overflow="true">
                <label
                  class="btn"
                  :for="`checkbox-${dataIndex}-${attrItem.name}`"
                ></label>

                <span style="white-space: pre-line">
                  {{ dataItem[attrItem.name] ? dataItem[attrItem.name] : "--" }}
                </span>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="title">源链接</div>
            <div class="value-wrapper">
              <div class="value">
                <a
                  @click="handleClickViewUrl(dataItem.url)"
                  style="color: rgba(125, 101, 217, 1)"
                >
                  {{ dataItem.url }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>暂无数据</div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
} from "vue";

const emits = defineEmits(["select-data"]);

const props = defineProps({
  dataList: {
    type: Array,
    default: () => [],
  },
  dataColumn: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// 选中逻辑
const selectedIndexList = ref([]);
const dataListTemp = ref([]);
watch(
  () => props.dataList,
  (newVal) => {
    dataListTemp.value = newVal;
    selectedIndexList.value =
      newVal?.map((item) => {
        return false;
      }) || [];
  },
  { deep: true, immediate: true }
);
const viewLoading = ref(false);
watch(
  () => props.loading,
  (newVal) => {
    viewLoading.value = newVal;
  },
  {
    immediate: true,
  }
);

//多选操作
const handleSelectData = () => {
  let result = dataListTemp.value.filter((item, index) => {
    return selectedIndexList.value[index];
  });
  emits("select-data", result);
};
//全选操作
// const handleSelectAllData = (value) => {
//   selectedIndexList.value.forEach((item) => (item = value));
//   handleSelectData();
// };
//查看链接
const handleClickViewUrl = (url) => {
  window.open(url, "_blank");
};
</script>

<style lang="scss" scoped>
.document-view {
  border: 1px solid rgba(224, 227, 234, 1);
  border-radius: 4px;
  .data-list {
    .data-item {
      display: flex;
      padding-right: 40px;
      .checkbox {
        width: 60px;
        padding: 12px 20px;
      }
      .content-box {
        flex: 1;
        padding: 20px 0;
        .content-item {
          display: flex;
          .title {
            width: 120px;
            color: rgba(154, 154, 154, 1);
            word-break: break-all;
          }
          .value-wrapper {
            display: flex; // 辅助定位
            flex: 1;
            // 隐藏checkbox
            .checkbox {
              display: none;
            }
            // 勾选后使得value类展开
            .checkbox:checked + .value {
              -webkit-line-clamp: 999;
              max-height: none;
            }
            // 勾选后显示收起
            .checkbox:checked + .value .btn::after {
              content: "收起";
            }

            .checkbox:checked + .value .btn::before {
              visibility: hidden; /*在展开状态下隐藏省略号*/
            }
            .value {
              line-height: 20px; //行高
              // 超出指定行数隐藏
              display: -webkit-box;
              -webkit-line-clamp: 10; //最大行数
              -webkit-box-orient: vertical;
              overflow: hidden;
              // 展开/收起按钮
              .btn {
                float: right;
                clear: both;
                color: $theme-color;
                cursor: pointer;
              }
              .btn::after {
                content: "展开";
              }
              // 为文本占位

              &::before {
                content: "";
                float: right;
                height: calc(100% - 20px);
              }
            }
          }
        }
        .content-item + .content-item {
          margin-top: 20px;
        }
      }
      .content-box:not(:first-child) {
        border-top: 1px solid rgba(224, 227, 234, 1);
      }
    }
  }
  .empty {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #838383;
  }
}
</style>
