<template>
  <div class="project-layout">
    <div class="project-menu">
      <el-menu :default-active="activeMenuItem" @select="handleSelectMenuItem">
        <el-menu-item
          v-for="(menuItem, menuIndex) in menuItemList"
          :key="menuItem.label"
          :index="String(menuIndex)"
        >
          <div :class="['iconfont', menuItem.icon]"></div>
          <div class="title">{{ menuItem.label }}</div>
        </el-menu-item>
      </el-menu>
    </div>
    <div
      class="project-page"
      :class="{
        'hide-background': ifHideBackground,
        'hide-padding': ifHidePadding,
      }"
    >
      <router-view #="{ Component }">
        <!-- 将渲染的子组件动态绑定到 component 组件中 -->
        <component
          ref="routerViewRef"
          :key="$route.fullPath"
          :is="Component"
          @setBreadcrumbList="setBreadcrumbList"
          @setPageTitle="setPageTitle"
          @setSpiderInfo="setSpiderInfo"
        />
      </router-view>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  watch,
  provide,
  defineExpose,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute, useRouter } from "vue-router";
import { PROJECT_MODULE } from "../../constant/router/module-name";

const route = useRoute();
const router = useRouter();
const emits = defineEmits([
  "setBreadcrumbList",
  "setPageTitle",
  "setSpiderInfo",
]);
const setBreadcrumbList = (result) => {
  emits("setBreadcrumbList", result);
};
const setPageTitle = (pageTitle) => {
  emits("setPageTitle", pageTitle);
};
const setSpiderInfo = (spiderInfo) => {
  emits("setSpiderInfo", spiderInfo);
};
const projectId = route.params.projectId;
provide("projectId", projectId);

const menuItemList = ref([
  {
    label: "概览",
    icon: "icon-lvzhou_gailan",
  },
  {
    label: "爬虫",
    icon: "icon-loudong",
  },
  {
    label: "任务",
    icon: "icon-xietongrenwu",
  },
  {
    label: "数据",
    icon: "icon-jurassic_data",
  },
  {
    label: "日志",
    icon: "icon-rizhizhongxin",
  },
  {
    label: "策略",
    icon: "icon-riqishijian_filled",
  },
]);

const ifHideBackground = computed(() => {
  if (route.name == "ProjectOverview" || route.name == "ProjectSchedule") {
    return true;
  }
  return false;
});
const ifHidePadding = computed(() => {
  if (
    route.name == "ProjectOverview" ||
    route.name == "ProjectSpider" ||
    route.name == "ProjectSchedule"
  ) {
    return true;
  }
  return false;
});

const activeMenuItem = ref("0");

watch(
  route,
  (newVal) => {
    // console.log("watch");
    activeMenuItem.value = newVal.meta.projectModule;
  },
  { deep: true, immediate: true }
);

const handleSelectMenuItem = (index) => {
  console.log(index);
  switch (index) {
    case PROJECT_MODULE.OVERVIEW:
      router.push({ name: "ProjectOverview", params: { projectId } });
      break;
    case PROJECT_MODULE.SPIDER:
      router.push({ name: "ProjectSpider", params: { projectId } });
      break;
    case PROJECT_MODULE.TASK:
      router.push({ name: "ProjectTaskview", params: { projectId } });
      break;
    case PROJECT_MODULE.DATA:
      router.push({ name: "ProjectData", params: { projectId } });
      break;
    case PROJECT_MODULE.LOG:
      router.push({ name: "ProjectLogview", params: { projectId } });
      break;
    case PROJECT_MODULE.SCHEDULE:
      router.push({ name: "ProjectSchedule", params: { projectId } });
      break;
  }
};

const routerViewRef = ref(null);
//启用爬虫
const handelClickEnableSpider = () => {
  routerViewRef.value.handelClickEnableSpider();
};
//撤销爬虫
const handleClickResetSpider = () => {
  routerViewRef.value.handleClickResetSpider();
};
//上线爬虫
const handleClickPublishSpider = () => {
  routerViewRef.value.handleClickPublishSpider();
};

defineExpose({
  handelClickEnableSpider,
  handleClickResetSpider,
  handleClickPublishSpider,
});
</script>

<style lang="scss" scoped>
:deep(.el-menu) {
  border: none;
}
$menu-width: 80px;
.project-layout {
  min-height: $project-page-min-height;
  display: flex;
  .project-menu {
    background: #fff;
    width: $menu-width;
    min-height: $project-page-min-height;
    margin-right: 20px;
    .el-menu-item {
      //   padding: 0 16px;
      margin: 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 60px;
      text-align: center;
      line-height: normal;
      --el-menu-hover-bg-color: rgba(127, 68, 240, 0.06);
      border-radius: 8px;
      color: #838383;
      .iconfont {
        // height: 50%;
        font-size: 18px;
      }
      .title {
        // height: 50%;
      }
      &.is-active {
        background: rgba(127, 68, 240, 0.06);
        color: #7f44f1;
      }
    }
    .el-menu-item:first-child {
      margin-top: 16px;
    }
  }
  .project-page {
    flex: 1;
    width: calc(100% - $menu-width - 20px);
    min-height: $project-page-min-height;
    background: #fff;
    padding: 20px;
  }
  .hide-background {
    background: inherit;
  }
  .hide-padding {
    padding: 0;
  }
}
</style>
