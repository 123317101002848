<template>
  <div class="page-option-bar">
    <span>
      <el-button @click="handleBatchRemove">批量删除</el-button>
    </span>

    <span>
      <el-button type="primary" @click="handleClickAddModuel">
        新建数据结构模板
      </el-button>
    </span>
  </div>
  <div class="input">
    <el-input
      clearable
      placeholder="请输入数据结构模板名称进行检索"
      v-model="keywords"
      @keyup.enter="handleSearchDataStruct"
    >
      <template #prefix>
        <el-icon><Search /></el-icon>
      </template>
    </el-input>
  </div>
  <div class="list-table">
    <div class="table-wrapper">
      <el-table
        :data="moduleDataList"
        style="width: 100%"
        v-loading="ifLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="数据结构模板名称">
          <template #default="scope">
            <TextEditor
              fontColor="#7F44F0"
              :text="scope.row.name"
              editable
              v-model:edit="scope.row.onEditing"
              v-model:loading="scope.row.ifShowLoading"
              @save="(text) => handleClickSaveModuleName(text, scope.row)"
              @go="handleClickName(scope.row)"
              @cancel="handleClickCancelRename(scope.row)"
            ></TextEditor>
          </template>
        </el-table-column>

        <el-table-column prop="fieldCount" label="字段数量">
          <template #default="scope">
            {{ scope.row.fieldCount }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间">
          <template #default="scope">
            {{ timeFormatter(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="updateTime" label="更新时间">
          <template #default="scope">
            {{ timeFormatter(scope.row.updateTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作">
          <template #default="scope">
            <template v-if="scope.row.name">
              <span class="edit option" @click="gotoDetail(scope.row)">
                详情
              </span>
              <span class="edit option" @click="handleClickDelete(scope.row)">
                删除
              </span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          small
          background
          layout="total , prev, pager, next,sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, defineEmits } from "vue";
import { usePagination } from "@/utils/usePagination";
import { timeFormatter } from "@/utils/time.js";

import { httpPost } from "@/api/httpService.js";

import TextEditor from "@/components/base/TextEditor.vue";

import { useRouter } from "vue-router";

import { ElMessage } from "element-plus";
const router = useRouter();
const keywords = ref("");
const ifLoading = ref(false);
const moduleDataList = ref([]);
const selectedItemList = ref([]);
const moduleName = ref("");
// 新建模板
const addModule = async () => {
  let params = {
    name: moduleName.value,
    fieldList: [],
  };
  let res = await httpPost(
    "/spiderDataStruct/v1/addSpidersStructField",
    params
  );
  if (res.code == 0) {
    ElMessage.success("添加成功");
  }
  return res;
};
// 重命名模板
const renameModuleName = async (spiderStructUid) => {
  let params = {
    spiderStructUid,
    name: moduleName.value,
    fieldList: [],
  };
  let res = await httpPost("/spiderDataStruct/v1/renameSpidersStruct", params);
  if (res.code == 0) {
    ElMessage.success("修改成功");
  }
  return res;
};
//新建模板名称（）
const handleClickSaveModuleName = async (text, row) => {
  moduleName.value = text;
  let value = text.trim();
  if (value === "") {
    ElMessage.warning("数据结构模板名称不能为空或空格");
  } else if (value.length < 2) {
    ElMessage.warning("数据结构模板名称最短为2位");
  } else {
    let res;
    if (row.spiderStructUid) {
      res = await renameModuleName(row.spiderStructUid);
    } else {
      res = await addModule();
    }
    if (res.code == 0) {
      getModuleDataList();
    }
  }
  row.ifShowLoading = false;
};
// 根据数据结构模板名称进行检索
const handleSearchDataStruct = async () => {
  ifLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    keywords: keywords.value,
  };
  let res = await httpPost("/spiderDataStruct/v1/searchSpidersStruct", params);
  if (res.code == 0) {
    moduleDataList.value = res.data.data;
    total.value = res.data.total;
  }
  ifLoading.value = false;
};
//获取模板名称列表
const getModuleDataList = async () => {
  ifLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    keywords: null,
  };
  let res = await httpPost("/spiderDataStruct/v1/searchSpidersStruct", params);
  if (res.code == 0) {
    moduleDataList.value = res.data.data;
    total.value = res.data.total;
  }
  ifLoading.value = false;
};
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
//批量删除
const handleBatchRemove = async () => {
  if (selectedItemList.value.length == 0) {
    ElMessage.warning("请选择");
    return;
  }
  let res = await deleteSpiderStruct(
    selectedItemList.value.map((item) => item.spiderStructureUid)
  );
  if (res.code == 0) {
    ElMessage.success("删除成功");
  } else {
    ElMessage.warning(res.message);
  }
  getModuleDataList();
};
//删除一个
const handleClickDelete = async (row) => {
  let res = await deleteSpiderStruct([row.spiderStructureUid]);
  if (res.code == 0) {
    ElMessage.success("删除成功");
  } else {
    ElMessage.warning(res.message);
  }
  getModuleDataList();
};
const deleteSpiderStruct = async (spiderStructureUidList) => {
  let params = {
    spiderStructureUidList,
  };
  // let res = await httpPost("/setting/data/type/delete/" + row.id);
  let res = await httpPost("/spiderDataStruct/v1/deleteSpidersStruct", params);
  return res;
};
//新建数据结构模板
const handleClickAddModuel = () => {
  moduleDataList.value.push({
    onEditing: true,
  });
};
//// 点击列表项名称
const handleClickName = (row) => {
  gotoDetail(row);
};
//取消
const handleClickCancelRename = (row) => {
  if (row.name !== undefined) {
  } else {
    moduleDataList.value.pop();
  }
};
//详情
const gotoDetail = (row) => {
  router.push({
    name: "DataStructureModuleDetailIndex",
    params: {
      dataId: row.spiderStructureUid,
    },
    query: {
      name: row.name,
    },
  });
};

const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getModuleDataList,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
.page-option-bar {
  top: -75px;
  right: 20px;
}
.list-table {
  margin-top: 20px;
}
.table-wrapper {
  .icon-DA {
    color: #7f44f0;
    margin-right: 10px;
  }
  .name {
    margin-right: 10px;
  }
  .edit {
    color: #7f44f0;
  }
  .option {
    cursor: pointer;
    margin-right: 10px;
  }
}
.input {
  width: 400px;
}
</style>
