<template>
  <div class="data-wrapper">
    <LogTable></LogTable>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
} from "vue";
import { getBreadcrumbList } from "./api/breadcrumb.js";
import LogTable from "./components/LogTable.vue";

const projectId = inject("projectId");
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "日志",
      name: "ProjectData",
      params: { projectId },
    },
  ];
  let result = await getBreadcrumbList(projectId, breadcrumbList);
  const pageTitle = "日志";
  emits("setBreadcrumbList", result);
  emits("setPageTitle", pageTitle);
};

setBreadcrumb();
</script>

<style lang="scss" scoped></style>
