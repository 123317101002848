<template>
  <div class="page-option-bar">
    <span>
      <el-button @click="goBack">返回</el-button>
    </span>

    <span v-if="btnType == BTN_TYPE.EDIT">
      <el-button type="primary" @click="handleClickEdit(BTN_TYPE.SAVE)">
        编辑
      </el-button>
    </span>
    <span v-if="btnType == BTN_TYPE.SAVE">
      <el-button type="primary" @click="handleClickSave(BTN_TYPE.EDIT)">
        保存
      </el-button>
    </span>
  </div>
  <div class="input" v-if="btnType == BTN_TYPE.SAVE">
    <el-form
      :model="structInfo"
      ref="formDataRef"
      label-width="150px"
      @submit.prevent
    >
      <el-form-item label="数据结构模板名称：" prop="structName">
        <el-input
          clearable
          v-model.trim="structInfo.structName"
          :placeholder="structInfo.structName"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
  <div class="list-table">
    <div class="list-header">
      <div class="title">字段列表</div>
      <div v-if="btnType == BTN_TYPE.SAVE">
        <el-button @click="handleBatchRemove">批量删除</el-button>
        <el-button type="primary" @click="handleClickAddField('ADD')">
          新增字段
        </el-button>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="fieldDataList"
        style="width: 100%"
        v-loading="ifLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="name" label="字段名称">
          <template #default="{ row }">
            <span class="edit">{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="字段说明" />
        <el-table-column prop="type" label="字段类型">
          <template #default="scope">
            {{ getFieldType(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="format" label="日期格式">
          <template #default="scope">
            {{ scope.row.format || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="size" label="字段长度">
          <template #default="scope">
            {{ scope.row.size || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="notNull" label="是否必须">
          <template #default="scope">
            {{ getNotNull(scope.row.notNull) }}
          </template>
        </el-table-column>
        <el-table-column prop="defaultValue" label="默认值" />
        <el-table-column
          prop="address"
          label="操作"
          v-if="btnType == BTN_TYPE.SAVE"
        >
          <template #default="{ row }">
            <span class="edit option" @click="handleClickEditItem('EDIT', row)">
              编辑
            </span>
            <span class="edit option" @click="handleRemoveItem(row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          small
          background
          layout="total , prev, pager, next,sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        />
      </div>
    </div>
    <AddFieldIdDLG
      ref="addFieldDLGRef"
      @refresh="refreshDataList"
    ></AddFieldIdDLG>
  </div>
</template>

<script setup>
import { ref, reactive, watch, defineEmits, inject } from "vue";
import { usePagination } from "@/utils/usePagination";
import { httpPost } from "@/api/httpService.js";

import { FIELD_TYPE_MAP } from "@/constant/field_type.js";

import { remindErrorMessage } from "@/utils/utils";

import { useRouter, useRoute } from "vue-router";
import SpiderStatusTag from "@/components/base/SpiderStatusTag.vue";
import { ElMessage, ElMessageBox, RowAlign } from "element-plus";
import AddFieldIdDLG from "./AddFieldIdDLG.vue";
const router = useRouter();
const route = useRoute();
const BTN_TYPE = {
  EDIT: 1,
  SAVE: 2,
};
const SELECT_TYPE = {
  NOTNULL: 0,
  SELECTED: 1,
};
const dataTypeId = ref("");
const btnType = ref(BTN_TYPE.EDIT);
const ifLoading = ref(false);
const fieldDataList = ref([]);
const selectedItemList = ref([]);
const spiderStructUid = route.params.dataId;
const fieldrowIndex = ref(-1);
const structInfo = ref({
  structName: "",
});
//未修改的模板字段名
const originalStructName = ref("");
const originalFieldList = ref([]);
// const spiderStructName = ref("");
//获取字段类型
const getFieldType = (type) => {
  return FIELD_TYPE_MAP[type]?.label || "--";
};
//获取是否必须
const getNotNull = (type) => {
  return type == SELECT_TYPE.SELECTED ? "是" : "否";
};
//获取字段列表
const getFieldDataList = async () => {
  ifLoading.value = true;
  let res = await httpPost("/spiderDataStruct/v1/searchSpidersStructContent", {
    spiderStructUid,
  });
  if (res.code == 0) {
    structInfo.value.structName = res.data.spidersStructName;
    fieldDataList.value = res.data.spidersDataStructConfigDTOList;
    originalStructName.value = res.data.spidersStructName;
    originalFieldList.value = JSON.parse(JSON.stringify(fieldDataList.value));
  } else {
    fieldDataList.value = [];
  }
  ifLoading.value = false;
};
const refreshDataList = (formData, type, index) => {
  if (type === "EDIT") {
    fieldDataList.value[index] = formData;
  } else {
    fieldDataList.value.push(formData);
  }
};
const saveStructInfo = async () => {
  let params = {
    spidersStructureUid: spiderStructUid,
    name: structInfo.value.structName,
    configList: fieldDataList.value,
  };
  let res = await httpPost(
    "/spiderDataStruct/v1/editSpidersStructConfig",
    params
  );
  if (res.code == 0) {
    ElMessage.success("保存成功");
  }
};
//编辑
const handleClickEdit = (type) => {
  btnType.value = type;
};
// const handleSetPageTitle = async () => {
//   let params = {
//     spidersStructureUid: dataTypeId.value,
//     name: structInfo.value.structName,
//   };
//   await httpPost("/spiderDataStruct/v1/renameSpidersStruct", params);
//   // if (res.code == 0) {
//   //   // ElMessage.success(res.message);
//   // }
// };
//保存
const handleClickSave = async () => {
  // await handleSetPageTitle();
  await saveStructInfo();
  // await getFieldDataList();
  // console.log("详情的保存");
  router.push({
    name: "DataStructureModuleDetailIndex",
  });
};
//检查数据结构模板字段是否发生改变
const checkStructInfoModify = () => {
  return checkStructNameModify() || checkStructFieldModify();
};
const checkStructNameModify = () => {
  return originalStructName.value !== structInfo.value.structName;
};
const checkStructFieldModify = () => {
  let ifModified;
  if (originalFieldList.value.length === fieldDataList.value.length) {
    ifModified = originalFieldList.value.some((originalItem, index) => {
      const currentItem = fieldDataList.value[index];
      return (
        originalItem.name !== currentItem.name ||
        originalItem.label !== currentItem.label ||
        originalItem.type !== currentItem.type ||
        originalItem.size !== currentItem.size
      );
    });
  } else {
    ifModified = true;
  }
};
//返回
const goBack = () => {
  // if (btnType.value == BTN_TYPE.EDIT) {
  // router.push({
  //   name: "DataStructureModuleIndex",
  // });
  // } else {
  // let ifModified;
  // if (originalFieldList.value.length === fieldDataList.value.length) {
  //   ifModified = originalFieldList.value.some((originalItem, index) => {
  //     const currentItem = fieldDataList.value[index];
  //     return (
  //       originalItem.name !== currentItem.name ||
  //       originalItem.label !== currentItem.label ||
  //       originalItem.type !== currentItem.type ||
  //       originalItem.size !== currentItem.size
  //     );
  //   });
  // } else {
  //   ifModified = true;
  // }
  // fieldDataList.value
  const ifModified = checkStructInfoModify();
  if (originalStructName.value !== structInfo.value.structName || ifModified) {
    ElMessageBox.confirm("您有未保存的更改，是否确认离开？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        // btnType.value = BTN_TYPE.EDIT;
        router.push({
          name: "DataStructureModuleIndex",
        });
      })
      .catch(() => {
        return;
      });
  } else {
    // btnType.value = BTN_TYPE.EDIT;
    router.push({
      name: "DataStructureModuleIndex",
    });
  }
};
// };

//编辑
const handleClickEditItem = (type, row) => {
  fieldrowIndex.value = fieldDataList.value.indexOf(row);
  addFieldDLGRef.value.openDLG(
    type,
    fieldDataList.value,
    dataTypeId.value,
    fieldrowIndex.value,
    row
  );
};

//删除
const handleRemoveItem = async (row) => {
  // TODO 重新对接sjs-待测试
  // let params = {
  //   spiderStructureUidList: [spiderStructUid],
  // };
  // let res = await httpPost("/spider/v1/deleteSpidersStruct", params);
  // if (res.code == 0) {
  //   ElMessage.success("删除成功");
  // } else {
  //   remindErrorMessage(res.message);
  // }
  const fieldIndex = fieldDataList.value.indexOf(row);
  if (fieldIndex !== -1) {
    fieldDataList.value.splice(fieldIndex, 1);
  }
  ElMessage.success("删除成功");
};
//批量删除
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
const handleBatchRemove = async () => {
  if (selectedItemList.value.length == 0) {
    ElMessage.warning("请选择字段列表");
  } else {
    fieldDataList.value = fieldDataList.value.filter((field) => {
      return !selectedItemList.value.some(
        (selectedItem) => selectedItem.dataTypeId === field.dataTypeId
      );
    });
    ElMessage.success("批量删除成功");
  }
};
//新增字段
const addFieldDLGRef = ref({});
const handleClickAddField = (type) => {
  addFieldDLGRef.value.openDLG(type, fieldDataList.value, dataTypeId.value);
};
//获取id
watch(
  () => route.params,
  (newVal, oldVal) => {
    dataTypeId.value = route.params.dataId;
  },
  { immediate: true, deep: true }
);
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getFieldDataList,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
// .page-option-bar {
//   position: absolute;
//   top: -96px;
//   right: -16px;
// }
.list-table {
  // margin-top: 20px;
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    .title {
      font-weight: 550;
      color: rgba(108, 108, 108, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-bold;
    }
  }
}
.table-wrapper {
  margin-top: 20px;
  .icon-DA {
    color: #7f44f0;
    margin-right: 10px;
  }
  .name {
    margin-right: 10px;
  }
  .edit {
    color: #7f44f0;
  }
  .option {
    cursor: pointer;
    margin-right: 10px;
  }
}
.input {
  width: 400px;
}
</style>
