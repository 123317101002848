<template>
  <div class="overview-card" v-loading="loading">
    <div class="header">
      <div class="title">
        <div class="content" v-showTip>
          <el-tooltip placement="bottom-start" effect="light">
            <template #content>
              {{ projectInfo.projectName }}
            </template>
            <div class="title-text">
              <span class="ellipse-text">
                {{ projectInfo.projectName }}
              </span>
            </div>
          </el-tooltip>
        </div>
        <ProjectStatusButton
          class="status-button"
          :status="projectInfo.projectStatus"
          @enable="enableProject"
          @pause="pauseProject"
          style="margin-left: 8px; position: relative; top: -4px"
        />
      </div>

      <div class="option">
        <i class="iconfont icon-edit" @click="handleClickEditProject" />
        <!-- v-if="projectInfo.createUser !== 0" -->
      </div>
    </div>
    <div class="main">
      <div class="main-info">
        <div class="info-tag">
          <span>项目标签：</span>
          {{
            projectInfo.projectTag && projectInfo.projectTag.length > 0
              ? projectInfo.projectTag.map((item) => item.tagName).join("、")
              : "--"
          }}
        </div>
        <div class="info-time">
          <span>创建时间：</span>
          {{ timeFormatter(projectInfo.projectCreateTime) }}
        </div>
      </div>
      <div class="main-desc">
        <span>项目描述：</span>
        {{
          projectInfo.projectDescription ? projectInfo.projectDescription : "--"
        }}
      </div>
    </div>
    <ProjectDialogManage
      ref="projectDialogManageRef"
      width="40%"
      @submit="getProjectInfo"
    />
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted, watch } from "vue";
import { httpPost } from "@/api/httpService.js";
import { timeFormatter } from "@/utils/time.js";
import ProjectDialogManage from "./ProjectDialogManage.vue";
import ProjectStatusButton from "@/components/base/ProjectStatusButton.vue";

const emits = defineEmits(["refresh", "enable", "pause"]);

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const projectInfo = ref({});
watch(
  () => props.data,
  (newVal) => {
    if (newVal.projectName) {
      projectInfo.value = JSON.parse(JSON.stringify(newVal));
      projectInfo.value.projectName =
        projectInfo.value.projectName || "项目名称";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

//获取项目详情
const getProjectInfo = () => {
  emits("refresh");
};

const enableProject = () => {
  emits("enable");
};
const pauseProject = () => {
  emits("pause");
};

//编辑采集项目
const projectDialogManageRef = ref(null);
const handleClickEditProject = () => {
  projectDialogManageRef.value.openDLG("EDIT", projectInfo.value);
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/overview-card.scss";
</style>
