<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    :title="DLGTitle"
    class="base-dialog"
    :close-on-click-modal="false"
  >
    <div class="list-table">
      <div class="option-bar">
        <div class="search-box">
          <el-input
            v-model="searchForm.keywords"
            placeholder="请输入关键词"
            style="width: 200px"
            clearable
          />
          <el-select
            v-model="searchForm.status"
            placeholder="请选择爬虫状态"
            style="width: 150px; margin-left: 16px"
            clearable
          >
            <el-option
              v-for="item in SPIDER_STATUS_OPTION"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button type="primary" @click="getSpiderList">查询</el-button>
        </div>
        <el-button type="primary" @click="goSpiderDesign">新建爬虫</el-button>
      </div>
      <el-table
        :data="spiderList"
        v-loading="tableLoading"
        header-cell-class-name="list-table-header"
        @select="handleSelectionChange"
        ref="tableRef"
        class="hideCheckbox"
        height="300px"
        width="1000px"
      >
        <el-table-column type="selection" width="45px" />
        <el-table-column prop="spiderName" label="爬虫名称" min-width="150px">
          <template #default="scope">
            <i class="iconfont icon-DA" />
            {{ scope.row.name }}
            <!-- <el-tag v-if="scope.row.isMarket == 1" type="primary" size="mini">
                已发布
              </el-tag>
              <el-tag v-if="scope.row.isMarket == 2" type="success" size="mini">
                已购买
              </el-tag> -->
          </template>
        </el-table-column>
        <!-- <el-table-column prop="spiderType" label="爬虫类型" width="85px">
          <template #default="scope">
            {{
              scope.row.spiderType
                ? SPIDER_TYPE_MAP[scope.row.spiderType].label
                : "--"
            }}
          </template>
        </el-table-column> -->
        <el-table-column prop="status" label="爬虫状态" width="150px">
          <template #default="scope">
            <SpiderStatusTag :status="scope.row.spiderStatus"></SpiderStatusTag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="100px">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.createTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="100px">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.updateTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px" fixed="right">
          <template #default="scope">
            <div class="list-item-btn" v-if="!scope.row.ifEdit">
              <div>
                <el-button link @click="goDetail(scope.row)" type="primary">
                  详情
                </el-button>
                <el-button
                  link
                  v-if="scope.row.spiderStatus !== SPIDER_STATUS.ENABLED"
                  @click="handleClickOperate(scope.row, 'enable')"
                  type="primary"
                >
                  启用
                </el-button>
                <el-button
                  link
                  v-if="scope.row.spiderStatus !== SPIDER_STATUS.DISABLED"
                  @click="handleClickOperate(scope.row, 'disable')"
                  type="primary"
                >
                  禁用
                </el-button>
                <el-button
                  link
                  v-if="scope.row.spiderStatus !== SPIDER_STATUS.DRAFT"
                  @click="handleClickOperate(scope.row, 'backToDraft')"
                  type="primary"
                >
                  退回到草稿
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClickConfirm(0)" :disabled="saveButtonDisable">
          <!-- 0 不启用 -->
          导入
        </el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm(1)"
          :disabled="saveButtonDisable"
        >
          <!-- 1 启用 -->
          导入并启用
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick,
  computed,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import { usePagination } from "@/utils/usePagination";
import { timeFormatter } from "@/utils/time.js";
import {
  SPIDER_STATUS_MAP,
  SPIDER_STATUS,
  SPIDER_STATUS_OPTION,
} from "@/constant/spider_status.js";
import { SPIDER_TYPE_MAP } from "@/constant/spider_type.js";
import VueCookies from "vue-cookies";
import SpiderStatusTag from "@/components/base/SpiderStatusTag.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";

const emits = defineEmits(["submit"]);
const route = useRoute();

// #region 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("配置爬虫");
const userId = ref(null);
const projectId = ref(null);
const openDLG = (params) => {
  if (params) {
    projectId.value = params.id;
  }
  userId.value = VueCookies.get("userInfo").userId;
  getSpiderList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetDLG();
  });
};
// 重置
const resetDLG = () => {
  searchForm.value.keywords = "";
  searchForm.value.status = "";
};
// #endregion 弹窗相关

// #region 获取列表相关
const tableLoading = ref(false);
const spiderList = ref([]);
const searchForm = ref({
  keywords: "",
  status: "",
});
const getSpiderList = async () => {
  tableLoading.value = true;
  // let params = {
  //   userId: userId.value,
  //   dataTypeId: null,
  //   keyword: searchForm.value.keywords,
  //   status: searchForm.value.status,
  //   ifMarket: 0,
  //   isMarket: 0,
  //   pageNum: pageNum.value,
  //   pageSize: pageSize.value,
  // };
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    keywords: searchForm.value.keywords,
    spiderStatus: searchForm.value.status,
  };
  let res = await httpPost("/spider/v1/searchSpiders", params);
  if (res.code == 0) {
    spiderList.value = res.data.data;
    pageNum.value = res.data.pageNum;
    pageSize.value = res.data.pageSize;
    total.value = res.data.total;
  }
  tableLoading.value = false;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getSpiderList,
  ifFetchOnMounted: false,
});
// #endregion 获取列表相关

const tableRef = ref(null);
const selectedItem = ref([]);
const handleSelectionChange = (val) => {
  //实现单选
  if (val.length > 1) {
    tableRef.value.clearSelection();
    let selectItem = val.pop();
    val = [selectItem];
    tableRef.value.toggleRowSelection(selectItem);
  }
  selectedItem.value = val;
};
//查看详情
const goDetail = (row) => {
  window.open(
    spiderDesignHost + `/spider/design/index.html?id=${row.spiderUid}`
  );
};
//改变状态
const handleClickOperate = (row, operate) => {
  switch (operate) {
    case "enable":
      changeStatus(row.spiderUid, SPIDER_STATUS.ENABLED);
      break;
    case "disable":
      confirmDeleteBox("禁用后，该爬虫将无法用于创建项目，确认禁用吗?")
        .then(async () => {
          await changeStatus(row.spiderUid, SPIDER_STATUS.DISABLED);
        })
        .catch(() => {});
      break;
    case "backToDraft":
      changeStatus(row.spiderUid, SPIDER_STATUS.DRAFT);
      break;
    default:
      break;
  }
};
const changeStatus = async (spiderUid, spiderStatus) => {
  let params = {
    spiderUid,
    spiderStatus,
  };
  let res = await httpPost("/spider/v1/changeSpidersStatus", params);
  if (res.code == 0) {
    getSpiderList();
    ElMessage.success("更新成功");
  }
};

// 点击确认
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
// 导入
const handleClickConfirm = Debounce(async (isEnable) => {
  showSaveButtonDisable();
  if (!selectedItem.value?.length) {
    ElMessage.warning("请先勾选爬虫模板");
    hideSaveButtonDisable();
    return;
  }

  //状态启用爬虫才能导入
  if (selectedItem.value[0].spiderStatus == SPIDER_STATUS.ENABLED) {
    let params = {
      projectUid: projectId.value,
      isSpiderEnable: isEnable,
      spiderUid: selectedItem.value[0].spiderUid,
    };
    let res = await httpPost("/projectSpider/v1/importSpider", params);
    if (res.code == 0) {
      emits("submit");
      isEnable == 0
        ? ElMessage.success("导入成功")
        : ElMessage.success("导入启用成功");
      closeDLG();
    } else {
      // hideSaveButtonDisable();
      // remindErrorMessage(res.message);
    }
    hideSaveButtonDisable();
  } else {
    hideSaveButtonDisable();
    ElMessage({
      type: "warning",
      message: "爬虫未启用, 不能导入项目",
    });
  }
});

//前往爬虫设计器
const goSpiderDesign = () => {
  window.open(
    spiderDesignHost + `/spider/design/index.html?projectId=${projectId.value}`
  );
};

defineExpose({ openDLG, closeDLG });

//监听爬虫设计器的postMessage
function handleReceiveMessage(event) {
  if (
    spiderDesignHost.indexOf(event.origin) != -1 &&
    event.data.name === "spider-design-refresh"
  ) {
    getSpiderList();
  }
}

onMounted(() => {
  //监听爬虫设计器的postMessage
  window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  window.removeEventListener("message", handleReceiveMessage);
});
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/base-dialog.scss";
@import "@/assets/styles/components/list-table.scss";
.hideCheckbox {
  :deep(.el-table__header-wrapper .el-checkbox) {
    display: none;
  }
}
</style>
