<template>
  <div class="page-option-bar">
    <span v-if="spiderXml">
      <el-button @click="openVersionRecordDrawer">版本记录</el-button>
      <el-button @click="handleClickEdit" type="primary">编辑爬虫</el-button>
    </span>
  </div>
  <SpiderVersionRecordDrawer ref="spiderVersionRef" />
  <div style="height: 100%" v-loading="ifShowLoading">
    <!-- <div style="height: 100%"> -->
    <iframe
      ref="spiderRef"
      id="spiderIframe"
      :src="spiderUrl"
      frameborder="0"
      style="width: 100%; height: 100%"
      v-if="spiderXml"
    ></iframe>
    <EmptyStatus
      :imageSrc="require('@/assets/imgs/empty2.png')"
      :size="350"
      v-if="ifShowEmptyStatus"
    >
      <template #description>
        <div class="empty">
          <div class="empty-text">
            您还未配置爬虫，立即
            <span class="config-button" @click="handleClickConfigSpider">
              配置爬虫
            </span>
          </div>
          <div class="empty-tip">爬虫一旦配置，将无法更换，只能编辑</div>
        </div>
      </template>
    </EmptyStatus>
    <SpiderDialogConfig
      ref="spiderDialogConfigRef"
      width="60%"
      @submit="getSpider"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
  defineExpose,
} from "vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import VueCookies from "vue-cookies";
import EmptyStatus from "@/components/base/EmptyStatus";
import { getBreadcrumbList } from "./api/breadcrumb.js";
import SpiderDialogConfig from "./components/SpiderDialogConfig.vue";
import {
  spiderDesignMessageListener,
  removeSpiderDesignMessageListener,
  spiderDesignMessageSender,
} from "@/utils/postMessage.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { remindErrorMessage } from "@/utils/utils";
import { SPIDER_STATUS } from "@/constant/spider_status.js";
import SpiderVersionRecordDrawer from "./components/SpiderVersionRecordDrawer.vue";

const projectUid = inject("projectId");

const emits = defineEmits([
  "setBreadcrumbList",
  "setPageTitle",
  "setSpiderInfo",
]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "爬虫",
      name: "ProjectSpider",
      params: { projectUid },
    },
  ];
  let result = await getBreadcrumbList(projectUid, breadcrumbList);
  emits("setBreadcrumbList", result);
  emits("setPageTitle", "");
};

const spiderRef = ref(null);
const openVersionRecordDrawer = () => {
  spiderVersionRef.value?.openDrawer({ projectUid });
};
onBeforeUnmount(() => {
  try {
    spiderRef.value.removeEventListener("load", hideLoading);
  } catch (error) {}
});

const ifShowLoading = ref(true);
const hideLoading = () => {
  ifShowLoading.value = false;
};
const ifShowEmptyStatus = ref(false);
const spiderXml = ref("");
const spiderVersionRef = ref(null);
const getSpider = async () => {
  let res = await httpPost("/projectSpider/v1/getProjectSpiderXml", {
    projectUid,
  });
  if (res.code == 0) {
    // res.data = null;
    if (res.data.spiderXml) {
      // ifShowLoading.value = false;
      // spiderXml.value = null;
      spiderXml.value = res.data.spiderXml;
      nextTick(() => {
        spiderRef.value.addEventListener("load", hideLoading);
      });
      await getSpiderInfo();
    } else {
      ifShowLoading.value = false;
      ifShowEmptyStatus.value = true;
      const pageTitle = "爬虫";
      emits("setPageTitle", pageTitle);
    }
  }
};

const info = ref({});
const getSpiderInfo = async () => {
  let params = {
    projectUid,
  };
  let res = await httpPost("/project/v1/searchProjectBasicInfo", params);
  if (res.code == 0) {
    info.value = res.data;
    console.log("🚀 ~ getSpiderInfo ~ info:", info);
    if (info.value.isBindingSpider) {
      emits("setPageTitle", info.value.spiderName);
      emits("setSpiderInfo", info.value);
    }
  }
};

const spiderUrl = computed(() => {
  // return `http://localhost:8900/?status=detail&iframe=true&id=${
  return (
    spiderDesignHost +
    `/spider/design/index.html?status=detail&iframe=true&projectId=${projectUid}&tokenWeb=${VueCookies.get(
      "tokenWeb"
    )}#/`
  );
});

//导入爬虫
const spiderDialogConfigRef = ref(null);
const handleClickConfigSpider = () => {
  spiderDialogConfigRef.value.openDLG({ id: projectUid });
};
//编辑爬虫
const handleClickEdit = () => {
  window.open(
    spiderDesignHost + `/spider/design/index.html?projectId=${projectUid}`
  );
};
//撤销爬虫更新
const handleClickResetSpider = async () => {
  let params = {
    projectUid,
  };
  let res = await httpPost("/projectSpider/v1/resetSpiderXMLDev", params);
  if (res.code == 0) {
    handlePostMessage();
    ElMessage.success("撤销更新成功");
  } else {
    remindErrorMessage(res.message);
  }
};
// 检查爬虫是否需要升级
const checkIfRenewVersion = async () => {
  let params = {
    projectUid: projectUid,
  };
  let res = await httpPost("/projectSpider/v1/isRenewSpiderVersion", params);
  return res;
};
const publishSpider = async (isRenewSpiderVersion) => {
  let params = {
    projectUid: projectUid,
    isRenewSpiderVersion,
  };
  let res = await httpPost("/projectSpider/v1/publishSpiderXMLDev", params);
  if (res.code == 0) {
    getSpiderInfo();
    ElMessage.success("上线更新成功");
  } else {
    remindErrorMessage(res.message);
  }
};
//上线爬虫更新
const handleClickPublishSpider = async () => {
  let ifRenewVersionRes = await checkIfRenewVersion();
  if (ifRenewVersionRes.code != 0) {
    // 检查失败跳出
    return;
  }
  if (ifRenewVersionRes.data) {
    ElMessageBox.confirm("发现爬虫版本更新，是否需要更新", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      publishSpider(true);
    });
  } else {
    publishSpider(false);
  }
};
//启用爬虫
const handelClickEnableSpider = async () => {
  let params = {
    projectUid,
    // isSpiderEnable: SPIDER_STATUS.ENABLED,
  };
  let res = await httpPost("/projectSpider/v1/enableSpider", params);
  if (res.code == 0) {
    getSpiderInfo();
    ElMessage.success("启用成功");
  }
};

const handlePostMessage = function () {
  getSpiderInfo();
  // parent.location.reload();
  ifShowLoading.value = true;
  let frame = document.querySelector("#spiderIframe").contentWindow;
  spiderDesignMessageSender(frame);
  nextTick(() => {
    spiderRef.value.addEventListener("load", hideLoading);
  });
};

//监听爬虫设计器的postMessage
function handleReceiveMessage(event) {
  if (
    spiderDesignHost.indexOf(event.origin) != -1 &&
    event.data.name === "spider-design-refresh"
  ) {
    // console.log(event.data);
    // console.log(event.origin);
    // console.log(event.source);
    handlePostMessage();
  }
}

onMounted(async () => {
  await setBreadcrumb();
  await getSpider();
  //监听爬虫设计器的postMessage
  window.addEventListener("message", handleReceiveMessage);
});
onBeforeUnmount(() => {
  window.removeEventListener("message", handleReceiveMessage);
});

defineExpose({
  handleClickResetSpider,
  handleClickPublishSpider,
  handelClickEnableSpider,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
.page-option-bar {
  top: -75px;
  right: 0px;
}
//空白状态
.empty {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;

  .empty-text {
    color: #6c6c6c;
    margin-bottom: 20px;
  }
  .empty-tip {
    color: #9a9a9a;
  }
  .config-button {
    color: $theme-color;
    cursor: pointer;
  }
}
</style>
