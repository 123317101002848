<template>
  <div class="task-record-container">
    <div class="task-header">
      <div class="header-item">
        任务ID：
        <el-select v-model="taskID" placeholder="请选择任务ID" clearable>
          <el-option
            v-for="item in taskIdOptions"
            :key="item.taskUid"
            :label="item.taskNumber"
            :value="item.taskUid"
          />
        </el-select>
      </div>
      <div class="header-item">
        时间：
        <el-date-picker
          :prefix-icon="customPrefix"
          v-model="timeRanges"
          type="daterange"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </div>

      <el-button type="primary" @click="handleClickInquire">查询</el-button>
    </div>
    <div class="task-content">
      <div class="table-box">
        <el-table :data="logDataList" style="width: 100%" v-loading="ifLoading">
          <el-table-column type="selection" width="55" />
          <!-- <el-table-column prop="logId" label="日志ID" width="150">
            <template #default="scope">
              <span>{{ scope.row.logId }}</span>
            </template>
          </el-table-column> -->
          <el-table-column type="index" width="55" label="序号" />
          <el-table-column prop="taskUid" label="任务ID" width="140">
            <template #default="{ row }">
              <span class="edit" @click="handleOpenDrawer(row)">
                {{ row.taskNumber }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="ifSenior"
            prop="tacticsName"
            label="策略名称"
          />
          <el-table-column prop="createTime" label="时间" width="170">
            <template #default="scope">
              {{ timeFormatter(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="logInfo" label="日志信息">
            <template #default="scope">
              <div>
                {{ generateLogText(scope.row) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
  </div>
  <LogDrawer ref="logViewDrawerRef"></LogDrawer>
</template>

<script setup>
import { reactive, ref, inject, shallowRef, h, defineEmits } from "vue";
import { httpPost } from "@/api/httpService.js";

import { usePagination } from "@/utils/usePagination";
import { timeFormatter } from "@/utils/time.js";

import LogDrawer from "./LogDrawer.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const projectId = ref(route.params.projectId);

const ifLoading = ref(false);
const logViewDrawerRef = ref({});
const ifSenior = ref(false);
const logDataList = ref([]);
const taskID = ref("");
const timeRanges = ref([]);
const taskIdOptions = ref([]);
const getTaskList = async () => {
  let params = {
    projectUid: projectId.value,
  };
  let res = await httpPost("/task/v1/searchProjectTaskNumber", params);
  if (res.code == 0) {
    taskIdOptions.value = res.data;
  }
};
getTaskList();

//设置日期选择器图标
const customPrefix = shallowRef({
  render() {
    return h(
      "p",
      <el-icon>
        <Clock />
      </el-icon>
    );
  },
});
//查询
const handleClickInquire = () => {
  getLogDataList(taskID.value);
};

const generateLogText = (row) => {
  const operationType = row.operationType;
  const taskEndTime = row.taskEndTime;
  const taskBeginTime = row.taskBeginTime;
  const collectNum = row.collectNum;

  let logText = "";
  switch (operationType) {
    case 1:
      logText = `${taskBeginTime} 创建`;
      break;
    case 2:
      logText = `${taskBeginTime} 开始采集`;
      break;
    case 3:
      logText = `${taskEndTime} 暂停采集, 共采集到${collectNum}条数据`;
      break;
    case 4:
      logText = `${taskEndTime} 结束采集, 共采集到${collectNum}条数据`;
      break;
    default:
      break;
  }

  return logText;
};

//打开抽屉
const handleOpenDrawer = (row) => {
  logViewDrawerRef.value.openDrawer({
    projectId: projectId.value,
    taskId: row.taskUid,
  });
};
const getLogDataList = async (taskUid = "") => {
  ifLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    projectUid: projectId.value,
    taskUid,
    beginTime: timeRanges.value[0],
    endTime: timeRanges.value[1],
  };
  let res = await httpPost("/log/v1/searchLogList", params);
  if (res.code == 0) {
    logDataList.value = res.data.data;
    total.value = res.data.total;
  }
  ifLoading.value = false;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getLogDataList,
});
</script>

<style lang="scss" scoped>
.task-record-container {
  .task-header-senior {
    :deep(.el-range-editor) {
      width: 224px;
    }
    .header {
      display: flex;
      margin-bottom: 24px;
    }
    .header-item {
      margin-right: 24px;
      color: rgba(108, 108, 108, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
      :deep(.el-select) {
        width: 145px;
      }
    }
    .create-time {
      margin-right: 38px;
    }

    .policy-name {
      :deep(.el-select) {
        width: 224px;
      }
    }
    .policy-name:deep(.el-select .el-input__inner) {
      cursor: text;
    }
  }
  .task-header {
    display: flex;
    :deep(.el-select) {
      width: 145px;
    }
    :deep(.el-range-editor) {
      width: 224px;
    }
    .header-item {
      margin-right: 24px;
      color: rgba(108, 108, 108, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
    }
  }
  .task-content {
    margin-top: 24px;
    .table-box {
      :deep(.el-table .el-table__cell) {
        height: 48px;
      }
      .edit {
        color: #7f44f0;
        cursor: pointer;
      }
      .option {
        cursor: pointer;
      }
    }
    .pagination-box {
      display: flex;
      margin-top: 24px;
      justify-content: flex-end;
    }
  }
}
</style>
