import { httpPost } from "@/api/httpService";

const ROOT = {
  title: "项目",

  name: "ProjectIndex",
  query: {},
};
export const getProjectInfo = async (projectUid) => {
  // let res = await httpPost("/project/v1/get/" + projectUid);
  let res = await httpPost("/project/v1/searchProjectBasicInfo", {
    projectUid,
  });
  return res.data;
};
let currentProjectId = "";
let projectInfo = {};
export const getBreadcrumbList = async (projectUid, breadcrumbList) => {
  let result = [];

  // if (currentProjectId != projectId) {
  currentProjectId = projectUid;
  projectInfo = await getProjectInfo(projectUid);
  // }
  result.push(ROOT);
  result.push({
    title: projectInfo.projectName,
    name: "ProjectOverview",
    params: { projectId: projectUid },
  });
  result = [...result, ...breadcrumbList];
  return result;
};
