<!-- 关联弹窗 -->
<template>
  <el-dialog
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
    class="base-dialog"
    :close-on-click-modal="false"
    width="500px"
    height="242px"
    center
  >
    <div class="select-schedule-box">
      <span class="schedule-name">策略名称:</span>
      <div class="schedule-options">
        <el-select v-model="scheduleValue" class="m-2" placeholder="请选择策略">
          <el-option
            v-for="item in AllScheduleCardDataList"
            :key="item.scheduleUid"
            :label="getItemLabel(item)"
            :value="item.scheduleUid"
            :disabled="
              item.status === SCHEDULE_STATUS.DISABLED ||
              item.status === SCHEDULE_STATUS.COMPLETED
            "
          />
        </el-select>
      </div>
    </div>
    <div class="button">
      <el-button @click="closeDLG">取消</el-button>
      <el-button type="primary" @click="handleClickConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, defineProps, defineEmits } from "vue";
import { SCHEDULE_STATUS } from "@/constant/schedule_status.js";
const DLGVisible = ref(false);
const DLGTitle = ref("关联策略");
const closeDLG = () => {
  DLGVisible.value = false;
};
const openDLG = () => {
  DLGVisible.value = true;
};
const props = defineProps({
  AllScheduleCardDataList: {
    type: Array,
    default: () => [],
  },
});

const getItemLabel = (info) => {
  if (info.status === SCHEDULE_STATUS.COMPLETED) {
    return info.name + "(已结束)";
  } else if (info.status === SCHEDULE_STATUS.DISABLED) {
    return info.name + "(未生效)";
  } else {
    return info.name;
  }
};

const emits = defineEmits(["onSelectSchedule"]);
const scheduleValue = ref("");
const handleClickConfirm = () => {
  closeDLG();
  const selectedSchedule = props.AllScheduleCardDataList.find(
    (item) => item.scheduleUid === scheduleValue.value
  );
  emits("onSelectSchedule", selectedSchedule);
};
defineExpose({
  openDLG,
});
</script>

<style lang="scss" scoped>
.base-dialog {
  position: relative;
  .select-schedule-box {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 80px;
  }
  .button {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 10px;
  }
}
</style>
