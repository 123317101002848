<template>
  <div class="overview-card" v-loading="loading">
    <div class="header">
      <div class="title">任务列表</div>
      <div class="option" v-if="tableData.length > 0" @click="goTask">更多</div>
    </div>
    <div class="main" v-if="tableData.length > 0">
      <div class="main-table">
        <el-table :data="tableData" style="width: 100%" height="350">
          <el-table-column
            prop="taskNumber"
            label="任务ID"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span class="column-id" @click="goData(scope.row)">
                {{ scope.row.taskNumber }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="170" />
          <el-table-column prop="collectNum" label="采集数量" width="100" />
          <el-table-column prop="status" label="状态" width="100">
            <template #default="scope">
              <TaskStatus :status="scope.row.status"></TaskStatus>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="empty" v-if="!loading && tableData.length === 0">
      <EmptyStatus :imageSrc="require('@/assets/imgs/empty1.png')">
        <template #description>
          <div class="empty-text">暂无内容</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, watch } from "vue";
import { httpPost } from "@/api/httpService.js";
import TaskStatus from "@/components/base/TaskStatus.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();

const projectId = ref(route.params.projectId);

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const tableData = ref([]);
watch(
  () => props.data,
  (newVal) => {
    tableData.value = newVal;
    console.log("🚀 ~ tableData:", tableData);
  },
  {
    immediate: true,
    deep: true,
  }
);

//前往任务页
const goTask = () => {
  router.push({
    name: "ProjectTaskview",
    params: {
      projectId: projectId.value,
    },
  });
};
//前往该任务数据页
const goData = (row) => {
  router.push({
    name: "ProjectData",
    params: {
      projectId: projectId.value,
    },
    query: {
      taskId: row.taskUid,
    },
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/overview-card.scss";
.column-id {
  color: $theme-color;
  cursor: pointer;
}
</style>
