import { spiderDesignHost } from "@/api/httpService.js";
import { nextTick } from "vue";

// 添加监听器
function handleReceiveMessage(event, callback) {
  if (
    event.origin === spiderDesignHost &&
    event.data.name === "spider-design-refresh"
  ) {
    // console.log(event.data);
    // console.log(event.origin);
    // console.log(event.source);
    callback(event);
  }
}

//监听爬虫设计器的postMessage
export function spiderDesignMessageListener(callback) {
  window.addEventListener("message", (event) =>
    handleReceiveMessage(event, callback)
  );
}

//移除监听
export function removeSpiderDesignMessageListener(callback) {
  window.removeEventListener("message", (event) =>
    handleReceiveMessage(event, callback)
  );
}

//向爬虫设计器postMessage
export const spiderDesignMessageSender = (ref, msg) => {
  const message = {
    name: "spider-design-refresh",
  };
  ref.postMessage(message, spiderDesignHost);
};
