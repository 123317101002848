<template>
  <div>
    <el-drawer
      v-model="drawerVisible"
      :before-close="closeDrawer"
      :title="drawerTitle"
      custom-class="base-drawer"
      size="40%"
    >
      <div v-if="!ifLoading && spiderVersionList.length > 0">
        <template
          v-for="spiderVersionInfo in spiderVersionList"
          :key="spiderVersionInfo.uid"
        >
          <SpiderVersionRecordCard
            :spiderVersionInfo="spiderVersionInfo"
            @refresh="getSpiderVersionList"
            @closeEdit="closeEdit"
            ref="spiderVersionRecordRef"
          />
        </template>
      </div>
      <div class="empty" v-if="!ifLoading && spiderVersionList.length === 0">
        <EmptyStatus :imageSrc="require('@/assets/imgs/empty2.png')">
          <template #description>
            <div class="empty-text">暂无版本记录</div>
          </template>
        </EmptyStatus>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, defineExpose } from "vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import SpiderVersionStatusTag from "../../../components/base/SpiderVersionStatusTag.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { ElMessage } from "element-plus";
import SpiderVersionRecordCard from "./SpiderVersionRecordCard.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

const drawerVisible = ref(false);
const projectUid = ref("");
const spiderVersionList = ref([]);
const spiderVersionRecordRef = ref(null);
const currentUid = ref(0);
const drawerTitle = ref("爬虫版本");

const ifLoading = ref(false);
// //TODO-获取爬虫版本列表待对接
const getSpiderVersionList = async (type = "") => {
  ifLoading.value = true;
  let params = {
    projectUid: projectUid.value,
  };
  let res = await httpPost("/projectSpider/v1/searchSpiderVersionList", params);
  if (res.code == 0) {
    spiderVersionList.value = res.data;
    if (type == "COPY") {
      ElMessage.success(`已为您使用${spiderVersionList.value[0].name}的爬虫`);
    }
    drawerTitle.value = `版本记录 (${spiderVersionList.value.length}/10)`;
    ifLoading.value = false;
  }
};
const closeDrawer = () => {
  drawerVisible.value = false;
  spiderVersionRecordRef.value.map((item) => item.closeEditor());
};
const openDrawer = (params) => {
  drawerVisible.value = true;
  init(params);
};
const closeEdit = (uid) => {
  spiderVersionRecordRef.value.map((item) => item.closeEditor());
};
const init = (params) => {
  projectUid.value = params.projectUid;
  getSpiderVersionList();
};
defineExpose({
  openDrawer,
});
</script>

<style lang="scss" scoped>
.version-card {
  margin-bottom: 20px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .spider-status {
    margin-right: 10px;
  }
  .version-operation {
    display: none;
  }
  &:hover .version-operation {
    display: flex;
    gap: 10px;
  }
}
.spider-description {
  word-wrap: break-word;
  cursor: pointer;
}
.spider-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  .copy-version-tip {
    color: $theme-color;
    cursor: pointer;
  }
}
.empty {
  height: $project-page-min-height;
}
</style>
