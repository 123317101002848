<template>
  <div class="overview-card" v-loading="loading">
    <div class="header">
      <div class="title">采集数量趋势</div>
    </div>
    <div class="main-card">
      <div class="main-header">
        <div class="time-type">
          <el-radio-group
            v-model="timeType"
            size="small"
            @change="handleChangeTimeType"
          >
            <el-radio-button label="hour">时</el-radio-button>
            <el-radio-button label="day">日</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </div>
        <div class="date-picker">
          <el-date-picker
            size="small"
            v-model="selectTime"
            :type="timePicker"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :format="format"
            :value-format="format"
            @change="changeTime"
            :key="format"
          />
        </div>
      </div>
      <div class="chart" ref="chartRef">
        <LineChart
          ref="lineChartRef"
          :chartData="data"
          :selectTime="selectTime"
          :timeType="timeType"
        ></LineChart>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  reactive,
  defineExpose,
  defineEmits,
} from "vue";
import * as echarts from "echarts";
import {
  getRecentTwentyFourHours,
  getRecentSevenDays,
  getRecentSixMonths,
} from "@/utils/time.js";
import { httpPost } from "@/api/httpService.js";
import LineChart from "@/components/base/LineChart.vue";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();
const projectId = ref(route.params.projectId);

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {};
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["change"]);
const selectTime = ref(getRecentSevenDays());
const timeType = ref("day");
const timePicker = ref("daterange");
const dataType = ref(2);
const format = ref("YYYY-MM-DD");
const chartRef = ref({});
const dataList = ref({});
const handleChangeTimeType = (val) => {
  if (val == "hour") {
    timePicker.value = "datetimerange";
    format.value = "YYYY-MM-DD HH:mm";
    dataType.value = 1;
    selectTime.value = getRecentTwentyFourHours();
  } else if (val == "day") {
    timePicker.value = "daterange";
    format.value = "YYYY-MM-DD";
    dataType.value = 2;
    selectTime.value = getRecentSevenDays();
  } else if (val == "month") {
    timePicker.value = "monthrange";
    format.value = "YYYY-MM";
    dataType.value = 3;
    selectTime.value = getRecentSixMonths();
  }
  getChartData();
};
//变换时间
const changeTime = async () => {
  await getChartData();
};
const getChartData = async () => {
  let params = getCondition();
  emits("change", params);
};
const getCondition = () => {
  let beginDate = selectTime.value[0];
  let endDate = selectTime.value[1];
  return {
    dateType: dataType.value,
    beginTime: beginDate,
    endTime: endDate,
  };
};

onMounted(() => {
  getChartData();
});

defineExpose({ getChartData, getCondition });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/overview-card.scss";
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .time-type {
    width: 120px;
  }
  :deep(.el-radio-button--small .el-radio-button__inner) {
    height: 28px;
    padding: 7px 11px;
  }
  .date-picker {
    text-align: right;
    flex: 1;
  }
  :deep(.el-range-editor--small.el-input__wrapper) {
    width: 300px;
    height: 28px;
  }
}

:deep(.el-radio-group) {
  flex-wrap: nowrap;
}
:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: rgba(127, 68, 240, 0.1);
  color: rgba(127, 68, 240, 1);
  border-color: rgba(127, 68, 240, 0.1);
  box-shadow: 0 0 0 0;
}
</style>
