<template>
  <div class="log-drawer">
    <el-drawer v-model="visible" class="drawer">
      <template #header>
        <span>任务详情</span>
      </template>

      <div class="content">
        <div class="item">
          <div class="name">任务ID：</div>
          <div class="message">{{ taskId }}</div>
        </div>
        <div class="item">
          <div class="name">策略名称：</div>
          <div class="message">{{ taskDetail.scheduleName || "--" }}</div>
        </div>
        <div class="item">
          <div class="name">创建时间：</div>
          <div class="message">{{ taskDetail.createTime }}</div>
        </div>
        <div class="item">
          <div class="name">完成时间：</div>
          <div class="message">{{ taskDetail.completedTime }}</div>
        </div>
        <div class="item">
          <div class="name">采集数量：</div>
          <div class="message">{{ taskDetail.collectNum }}</div>
          <div class="see-data" @click="gotoDataPage(taskId)">查看数据</div>
        </div>
        <div class="item">
          <div class="name">状态：</div>
          <div class="message">
            <TaskStatus :status="taskDetail.status"></TaskStatus>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, defineExpose } from "vue";

import { httpPost } from "@/api/httpService.js";

import TaskStatus from "@/components/base/TaskStatus.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const projectId = ref(null);
const taskId = ref("");
const visible = ref(false);
const openDrawer = (params) => {
  visible.value = true;
  projectId.value = params.projectId;
  taskId.value = params.taskId;
  getTaskDetail();
};
//获取任务详情
const taskDetail = ref({});
const getTaskDetail = async () => {
  let params = {
    taskUid: taskId.value,
  };
  // 接口
  let res = await httpPost("/task/v1/searchTaskDetail", params);
  if (res.code == 0) {
    taskDetail.value = res.data;
  }
};

//查看数据
const gotoDataPage = (id) => {
  router.push({
    name: "ProjectData",
    params: {
      projectId: projectId.value,
    },
    query: {
      taskId: id,
    },
  });
};
defineExpose({ openDrawer });
</script>

<style lang="scss" scoped>
.log-drawer {
  .content {
    padding: 12px;
  }
  .item {
    display: flex;
    margin-bottom: 28px;
    color: rgba(154, 154, 154, 1);
    .name {
      width: 70px;
      margin-right: 27px;
      text-align: right;
      font-family: SourceHanSansSC-regular;
    }
    .message {
      font-family: Roboto;
      color: #6c6c6c;
    }
    .see-data {
      margin-left: 20px;
      color: rgba(127, 68, 240, 1);
      cursor: pointer;
      font-size: 12px;
    }
  }
  :deep(.el-drawer__header) {
    height: 70px;
    padding: 20px;
    border-bottom: 1px solid rgba(220, 220, 220, 1);
    margin-bottom: 0;
    text-align: center;
    color: rgba(16, 16, 16, 1);
    font-size: 18px;
  }
}
</style>
