<template>
  <div class="task-statistics-card">
    <div class="status-icon">
      <el-tooltip content="当前无采集任务" placement="top" effect="light">
        <img
          :src="require('@/assets/imgs/project/task-statistics-not-start.png')"
          v-if="!isRunningTemp"
          alt=""
        />
      </el-tooltip>
      <el-tooltip content="采集进行中" placement="top" effect="light">
        <img
          :src="
            require('@/assets/imgs/project/task-statistics-in-progress.png')
          "
          v-if="isRunningTemp"
          alt=""
        />
      </el-tooltip>
    </div>
    <div class="status-item">
      <div class="title">任务已完成</div>
      <div class="count">
        {{ countMap.completedTaskNum ? countMap.completedTaskNum : 0 }}
      </div>
    </div>
    <div class="status-item">
      <div class="title">任务进行中</div>
      <div class="count">
        {{ countMap.runningTaskNum ? countMap.runningTaskNum : 0 }}
      </div>
    </div>
    <div class="status-item">
      <div class="title">
        采集总量

        <el-tooltip placement="top" effect="light">
          <template #content>
            <p style="width: 240px">
              第一个数字统计的是项目中当前的采集量，第二个数字统计的是累计采集总量。
            </p>
          </template>
          <i class="iconfont icon-xinxitishi"></i>
        </el-tooltip>
      </div>
      <div class="count">
        {{ formatNumber(countMap.currCollectNum) }}/{{
          formatNumber(countMap.totalCollectNum)
        }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, watch } from "vue";
import { formatNumber } from "@/utils/utils";

const props = defineProps({
  isRunning: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => {},
  },
});

const countMap = ref({});
watch(
  () => props.data,
  (newVal) => {
    countMap.value = newVal;
  },
  {
    immediate: true,
    deep: true,
  }
);
const isRunningTemp = ref(false);
watch(
  () => props.isRunning,
  (newVal) => {
    isRunningTemp.value = newVal;
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/task-statistics-card.scss";
.status-icon {
  img {
    width: 64px;
    height: 64px;
  }
}
</style>
