<template>
  <!-- <el-tag :type="type" :color="bgColor" :style="style" :key="type">
    {{ title }}
  </el-tag> -->
  <!-- TODO 修改样式 -->
  <el-dropdown size="small" type="primary" @command="handleCommand">
    <el-button size="small" :style="style" class="status-button">
      <div class="info">
        <span>{{ title }}</span>
        <div class="line" :style="lineColor"></div>
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      </div>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          command="enable"
          v-if="status != PROJECT_STATUS.ENABLE"
        >
          启用
        </el-dropdown-item>
        <el-dropdown-item
          command="pause"
          v-if="status == PROJECT_STATUS.ENABLE"
        >
          暂停
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { defineProps, computed, defineEmits } from "vue";
import {
  PROJECT_STATUS,
  PROJECT_STATUS_MAP,
} from "@/constant/project_status.js";
const props = defineProps({
  status: {
    type: Number,
  },
});
const emits = defineEmits(["enable", "pause"]);
const type = computed(() => {
  return PROJECT_STATUS_MAP[props.status]?.type || "";
});
const title = computed(() => {
  return PROJECT_STATUS_MAP[props.status]?.label || "";
});
const style = computed(() => {
  // const type = PROJECT_STATUS_MAP[props.status]?.type;
  if (type.value === PROJECT_STATUS_MAP[PROJECT_STATUS.ENABLE].type) {
    return `color: #f48f42; background-color: rgba(244, 143, 66, 0.1); border: none;`;
  } else if (type.value === PROJECT_STATUS_MAP[PROJECT_STATUS.PAUSE].type) {
    return `background-color: rgba(11, 136, 222, 0.1);
    color: rgba(11, 136, 222, 1);
    border: none;
`;
  } else if (type.value === PROJECT_STATUS_MAP[PROJECT_STATUS.READY].type) {
    return `color: rgba(154, 154, 154, 1); background-color: rgba(190, 190, 190, 0.3); border: none;`;
  } else {
    return `color: #1A78FF;border-color:#3894FF26`;
  }
});
const lineColor = computed(() => {
  if (type.value === PROJECT_STATUS_MAP[PROJECT_STATUS.ENABLE].type) {
    return `border-right: 1px solid rgba(244, 143, 66, 1);`;
  } else if (type.value === PROJECT_STATUS_MAP[PROJECT_STATUS.PAUSE].type) {
    return `border-right: 1px solid rgba(11, 136, 222, 1);
`;
  } else if (type.value === PROJECT_STATUS_MAP[PROJECT_STATUS.READY].type) {
    return `border-right: 1px solid rgba(190, 190, 190, 1);`;
  } else {
    return `borde-right:1px solid #3894FF26`;
  }
});
const bgColor = computed(() => {
  const type = PROJECT_STATUS_MAP[props.status]?.type;
  if (type) {
    return null;
  } else {
    return `#3894FF26`;
  }
});

const handleCommand = (command) => {
  switch (command) {
    case "enable":
      enableProject();
      break;
    case "pause":
      pauseProject();
      break;
  }
};
const enableProject = () => {
  emits("enable");
};
const pauseProject = () => {
  emits("pause");
};
</script>

<style lang="scss" scoped>
.status-button {
  width: 84px;
  height: 28px;
}
.line {
  width: 0px;
  height: 12px;
}
.info {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
