<template>
  <div class="spider-template-index">
    <DataStructureModule></DataStructureModule>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from "vue";
import { httpPost } from "@/api/httpService.js";
import DataStructureModule from "./components/DataStructureModuleTable.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "爬虫模板",
      name: "SpiderTemplateIndex",
      params: {},
    },
    {
      title: "数据结构模板管理",
      name: "DataStructureModuleIndex",
      params: {},
    },
  ];
  const pageTitle = "";
  emits("setBreadcrumbList", breadcrumbList);
  emits("setPageTitle", pageTitle);
};

onMounted(async () => {
  await setBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
