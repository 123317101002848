<template>
  <div class="page-option-bar">
    <span>
      <!-- <el-tooltip placement="left" effect="light">
        <template #content>
          点击此按钮系统会为您立即执行采集任务，您也可以
          <br />
          <span class="add-button">新建策略</span>
          ，设置采集任务定时执行/重复执行。
        </template> -->
      <el-button @click="handleClickEndTask" v-show="isRunning">
        结束任务
      </el-button>
      <el-button
        type="primary"
        :disabled="ifCrawlingButtonDisabled"
        @click="handleClickCrawling"
      >
        立即采集
      </el-button>
      <!-- </el-tooltip> -->
    </span>
  </div>
  <div class="overview-wrapper">
    <div style="height: 170px">
      <ProjectInfoCard
        :data="info"
        :loading="loading"
        @refresh="refreshPage"
        @enable="setProjectStatus(PROJECT_STATUS.ENABLE)"
        @pause="setProjectStatus(PROJECT_STATUS.PAUSE)"
      />
    </div>
    <div style="height: 170px">
      <ProjectProcessCard
        :activeStep="getActiveStep"
        @config-spider="handleClickConfigSpider"
        @crawling="handleClickCrawling"
      />
    </div>
    <div style="height: 170px">
      <SpiderInfoCard :data="info" :loading="loading" @refresh="refreshPage" />
    </div>
    <div style="height: 170px">
      <TaskStatisticsCard :data="taskAndCollectInfo" :isRunning="isRunning" />
    </div>
    <div style="height: 450px">
      <TaskTableCard :data="taskInfoList" :loading="loading" />
    </div>
    <div style="height: 450px">
      <TrendChartCard
        ref="trendChartCardRef"
        :loading="loading"
        :data="taskAndCollectInfo.collectTrend"
        @change="getHomePageData"
      />
    </div>
    <SpiderDialogConfig
      ref="spiderDialogConfigRef"
      width="60%"
      @submit="getProjectInfo"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  computed,
  onBeforeUnmount,
  watch,
} from "vue";
import { getBreadcrumbList } from "./api/breadcrumb.js";
import { httpPost } from "@/api/httpService.js";
import ProjectProcessCard from "@/components/projectCard/ProjectProcessCard.vue";
import TrendChartCard from "./components/TrendChartCard.vue";
import ProjectInfoCard from "./components/ProjectInfoCard.vue";
import SpiderInfoCard from "./components/SpiderInfoCard.vue";
import TaskTableCard from "./components/TaskTableCard.vue";
import TaskStatisticsCard from "./components/TaskStatisticsCard.vue";
import SpiderDialogConfig from "./components/SpiderDialogConfig.vue";
import { ElMessage } from "element-plus";
import { PROCESS_STEP } from "@/constant/process_step.js";
import { remindErrorMessage } from "@/utils/utils";
import { Debounce } from "@/utils/utils.js";
import {
  PROJECT_STATUS_MAP,
  PROJECT_STATUS,
} from "@/constant/project_status.js";
import { SPIDER_STATUS } from "@/constant/spider_status.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";

const projectId = inject("projectId");
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

//设置面包屑
const setBreadcrumb = async () => {
  const breadcrumbList = [
    {
      title: "概览",
      name: "ProjectOverview",
      params: { projectId },
    },
  ];
  let result = await getBreadcrumbList(projectId, breadcrumbList);
  const pageTitle = "概览";
  emits("setBreadcrumbList", result);
  emits("setPageTitle", pageTitle);
};

//获取项目详情
const info = ref({
  projectUid: "",
  projectName: "",
  projectTag: [
    // {
    //   tagUid: "",
    //   tagName: "",
    // },
  ],
  projectStatus: null,
  projectDescription: "",
  projectCreateTime: "",
  spiderName: "",
  spiderType: 0,
  spiderDescription: "",
  spiderUpdateTime: "",
});
// 任务及采集数量
const taskAndCollectInfo = ref({});
// 任务列表详情
const taskInfoList = ref([]);
//采集数量趋势年月日
const dataType = ref(1);
const beginTime = ref("");
const endTime = ref("");
const loading = ref(false);
const getProjectInfo = async () => {
  // loading.value = true;
  let params = {
    projectUid: projectId,
  };
  let res = await httpPost("/project/v1/searchProjectBasicInfo", params);
  if (res.code == 0) {
    info.value = res.data;
  }
  // loading.value = false;
};

const setProjectStatus = async (status) => {
  let params = {
    projectUid: projectId,
    status,
  };
  let res = await httpPost("/project/v1/updateProjectStatus", params);
  if (res.code == 0) {
    getProjectInfo();
    ElMessage.success("修改状态成功");
  }
};

// 获取任务列表
const getTaskList = async () => {
  let params = {
    pageNum: "1",
    pageSize: "10",
    projectUid: projectId,
  };
  let res = await httpPost("/task/v1/searchTaskList", params);
  if (res.code == 0) {
    taskInfoList.value = res.data.data;
  }
};
//配置爬虫
const spiderDialogConfigRef = ref(null);
const handleClickConfigSpider = () => {
  spiderDialogConfigRef.value.openDLG({ id: projectId });
};
//需要轮询的方法
const trendChartCardRef = ref(null);
const handlePolling = () => {
  // getProjectInfo();
  getTaskList();
  trendChartCardRef.value.getChartData();
};
//开始轮询
const intervalId = ref(null);
const startPolling = () => {
  clearInterval(intervalId.value);
  intervalId.value = setInterval(handlePolling, 5000); // 每5秒执行一次轮询
};
const stopPolling = () => {
  clearInterval(intervalId.value);
};

//结束任务
const handleClickEndTask = () => {
  confirmDeleteBox("确认结束排队中的采集任务吗？").then(async () => {
    let params = {
      projectUid: projectId,
    };
    let res = await httpPost("/collect/v1/finishCollectTask", params);
    if (res.code == 0) {
      ElMessage.success("结束任务指令发送成功");
      refreshTaskList();
    }
  });
};

const ifCrawlingButtonDisabled = ref(false);
//立即采集
const handleClickCrawling = Debounce(async () => {
  //完善校验逻辑和交互逻辑
  if (info.value.projectStatus == PROJECT_STATUS.READY) {
    if (
      !info.value.isBindingSpider ||
      info.value.spiderStatus == SPIDER_STATUS.DRAFT
    ) {
      ElMessage.warning("当前项目准备中，请先启动项目和爬虫");
      return;
    } else {
      ElMessage.warning("当前项目准备中，请先启动项目");
      return;
    }
  } else if (info.value.projectStatus == PROJECT_STATUS.PAUSE) {
    if (
      !info.value.isBindingSpider ||
      info.value.spiderStatus == SPIDER_STATUS.DRAFT
    ) {
      ElMessage.warning("当前项目暂停中，请先启动项目和爬虫");
      return;
    } else {
      ElMessage.warning("当前项目暂停中，请先启动项目");
      return;
    }
  }
  // else (!info.value.isBindingSpider) {
  //   ElMessage.warning("项目未配置爬虫，请先配置爬虫并启用");
  //   return;
  // }
  // }

  if (!info.value.spiderStatus == SPIDER_STATUS.DRAFT) {
    ElMessage.warning("项目未启用爬虫，请先启用爬虫");
    return;
  }
  ifCrawlingButtonDisabled.value = true;
  let params = {
    projectUid: projectId,
  };

  let res = await httpPost("/collect/v1/startCollect", params);
  if (res.code == 0) {
    // await getProjectInfo();
    refreshTaskList();
    if (res.data) {
      ElMessage.success("开始执行采集");
    } else {
      remindErrorMessage(res.message);
    }
  }
  ifCrawlingButtonDisabled.value = false;
});
// 获取主页右边数据
const getHomePageData = async (condition) => {
  let params = {
    type: 2, //2项目
    projectUid: projectId,
    ...condition,
  };
  console.log("🚀 ~ getHomePageData ~ params.condition:", params.condition);
  let res = await httpPost("/project/v1/searchHomePageData", params);
  if (res.code == 0) {
    taskAndCollectInfo.value = res.data;
  }
};
//判断项目进度
const getActiveStep = computed(() => {
  if (info.value.spiderName) {
    //如果有任务正在执行
    if (
      (taskAndCollectInfo.value.runningTaskNum &&
        taskAndCollectInfo.value.runningTaskNum !== 0) ||
      (taskAndCollectInfo.value.waitingTaskNum &&
        taskAndCollectInfo.value.waitingTaskNum !== 0)
    ) {
      return PROCESS_STEP.STEP_GET_DATA;
      //如果无任务正在执行
    } else {
      //如果已完成任务增多
      if (taskAndCollectInfo.value.completedTaskNum > oldFinishTaskNum.value) {
        return PROCESS_STEP.STEP_COMPLETE;
      }
      return PROCESS_STEP.STEP_EXECUTE_CRAWLING;
    }
  } else {
    return PROCESS_STEP.STEP_CONFIG_SPIDER;
  }
});
//判断是否正在进行
const isRunning = computed(() => {
  if (
    (taskAndCollectInfo.value.runningTaskNum &&
      taskAndCollectInfo.value.runningTaskNum !== 0) ||
    (taskAndCollectInfo.value.waitingTaskNum &&
      taskAndCollectInfo.value.waitingTaskNum !== 0)
  ) {
    return true;
  } else {
    return false;
  }
});

watch(
  () => taskAndCollectInfo.value.runningTaskNum,
  (newVal) => {
    //有任务在进行中或者有任务在排队
    if (
      (newVal && newVal !== 0) ||
      (taskAndCollectInfo.value.waitingTaskNum &&
        taskAndCollectInfo.value.waitingTaskNum !== 0)
    ) {
      startPolling();
    } else {
      stopPolling();
    }
  }
);

watch(
  () => taskAndCollectInfo.value.waitingTaskNum,
  (newVal) => {
    //有任务在进行中或者有任务在排队
    if (
      (newVal && newVal !== 0) ||
      (taskAndCollectInfo.value.runningTaskNum &&
        taskAndCollectInfo.value.runningTaskNum !== 0)
    ) {
      startPolling();
    } else {
      stopPolling();
    }
  }
);

const oldFinishTaskNum = ref("");
const refreshPage = async () => {
  loading.value = true;
  await getProjectInfo();
  await getTaskList();
  await getHomePageData(trendChartCardRef.value.getCondition());
  loading.value = false;
};
const refreshTaskList = Debounce(async () => {
  await getTaskList();
  await getHomePageData(trendChartCardRef.value.getCondition());
});
onMounted(async () => {
  setBreadcrumb();
  loading.value = true;
  await getProjectInfo();
  await getTaskList();
  // await getHomePageData();
  loading.value = false;
  oldFinishTaskNum.value = taskAndCollectInfo.value.completedTaskNum;
});

onBeforeUnmount(() => {
  stopPolling();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
.page-option-bar {
  top: -75px;
  right: 0px;
}
.add-button {
  font-size: 12px;
  color: $theme-color;
  cursor: pointer;
}
.overview-wrapper {
  $grid-gap-width: 20px;
  $columns: 2;
  display: grid;
  grid-gap: $grid-gap-width;
  grid-template-rows: 3;
  grid-template-columns: repeat(
    $columns,
    calc(100% / $columns - $grid-gap-width / $columns)
  );
  > div {
    width: 100%;
  }
}
</style>
