<template>
  <div :tabindex="tabIndex" ref="spiderVersionRef">
    <el-card
      @mouseenter="showCopyVersionTip"
      @mouseleave="closeCopyVersionTip"
      :class="{ active: ifEdit, version: true }"
      shadow="hover"
      @click="!ifEdit && goSpiderDesign(spiderVersionInfo.uid)"
    >
      <template #header>
        <div class="card-header">
          <div class="card-left" v-if="spiderVersionInfo.isTaskEffect === 1">
            <span class="spider-status">
              <SpiderVersionStatusTag
                :status="spiderVersionInfo.isTaskEffect"
              />
            </span>
          </div>
          <span v-if="!ifEdit" class="spider-name">
            {{ spiderVersionInfo.name }}
          </span>
          <el-input
            v-if="ifEdit"
            v-model="spiderVersionName"
            class="el-input version-editor"
            ref="spiderVersionNameRef"
            :id="'versionNameEditor' + spiderVersionInfo.uid"
            @keyup.enter="onSaveSpiderRecord(spiderVersionInfo.uid)"
          ></el-input>
          <div class="version-operation" @click.stop>
            <span
              class="version-edit"
              style="cursor: pointer"
              @click.stop="showEditInput(spiderVersionInfo.uid)"
            >
              <i class="iconfont icon-bianji" style="color: #6c6c6c"></i>
            </span>
            <span
              @click.stop="handleDeleteVersion(spiderVersionInfo.uid)"
              style="cursor: pointer"
              v-if="spiderVersionInfo.isTaskEffect !== 1"
            >
              <i class="iconfont icon-shanchu1" style="color: #6c6c6c"></i>
            </span>
          </div>
        </div>
      </template>
      <div class="spider-content" v-if="!ifEdit">
        <span class="spider-description">
          {{ spiderVersionInfo.versionDescription }}
        </span>
        <div class="spider-info" v-if="!ifEdit">
          <div class="author" v-if="!ifEdit">
            {{ spiderVersionInfo.createUserName }}
          </div>
          <div
            class="time"
            v-if="
              spiderVersionInfo.isTaskEffect === 1 ||
              (!ifShowCopyVersionTip && !ifEdit)
            "
          >
            {{ spiderVersionInfo.createTime }}
          </div>
          <div
            class="copy-version-tip"
            v-if="
              ifShowCopyVersionTip &&
              !ifEdit &&
              spiderVersionInfo.isTaskEffect !== 1
            "
            @click.stop="handleCopyAndUseSpider(spiderVersionInfo)"
          >
            复制并使用该版本
          </div>
        </div>
      </div>
      <div class="spider-editor" v-if="ifEdit">
        <el-input
          type="textarea"
          v-if="ifEdit"
          v-model="spiderDescription"
          :id="'textareaEditor' + spiderVersionInfo.uid"
          :rows="1"
          maxlength="200"
          ref="spiderTextareaRef"
          @keyup.enter="onSaveSpiderRecord(spiderVersionInfo.uid)"
          show-word-limit
          @click.stop
          class="el-input textarea-editor"
        />
      </div>
    </el-card>
  </div>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  nextTick,
  onMounted,
} from "vue";
import { httpPost, spiderDesignHost } from "@/api/httpService.js";
import SpiderVersionStatusTag from "../../../components/base/SpiderVersionStatusTag.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { ElMessage } from "element-plus";

const props = defineProps({
  spiderVersionInfo: {
    type: Object,
    default: () => {},
  },
});

const spiderVersionRef = ref(null);

const spiderTextareaRef = ref(null);

const spiderVersionName = ref(
  JSON.parse(JSON.stringify(props.spiderVersionInfo.name))
);
const spiderVersionNameRef = ref(null);

const spiderDescription = ref(
  JSON.parse(JSON.stringify(props.spiderVersionInfo.versionDescription))
);
const closeEditor = () => {
  ifEdit.value = false;
};
const tabIndex = ref(null);
const cardRef = ref(null);
const emits = defineEmits(["refresh", "closeEdit"]);

const ifShowCopyVersionTip = ref(false);
const ifEdit = ref(false);

// const handleSaveRecord = async (e) => {
//   // console.log(e.target)
// };
const showCopyVersionTip = () => {
  ifShowCopyVersionTip.value = true;
};
const closeCopyVersionTip = () => {
  ifShowCopyVersionTip.value = false;
};
//TODO-删除版本-待对接
const handleDeleteVersion = async (uid) => {
  confirmDeleteBox("确认删除此爬虫版本吗？")
    .then(() => {
      onDeleteVersion(uid);
    })
    .catch(() => {});
};
// const handleFocus = () => {
//   console.log("11111");
// };
const showEditInput = (uid) => {
  tabIndex.value = -1;
  nextTick(() => {
    spiderVersionRef.value.focus();
    spiderVersionNameRef.value.focus();
  });
  // emits("closeEdit", uid);
  ifEdit.value = true;
};
const onDeleteVersion = async (uid) => {
  let params = {
    uid,
  };
  let res = await httpPost("/projectSpider/v1/deleteSpiderVersion", params);
  if (res.code == 0) {
    ElMessage.success("删除成功");
    emits("refresh");
  }
};
//TODO编辑保存爬虫记录
const onSaveSpiderRecord = async (uid) => {
  let params = {
    uid,
    name: spiderVersionName.value,
    versionDescription: spiderDescription.value,
  };
  let res = await httpPost("/projectSpider/v1/saveSpiderVersion", params);
  if (res.code == 0) {
    ElMessage.success("编辑成功");
    ifEdit.value = false;
    emits("refresh");
  }
};
//TODO-复制并使用爬虫版本
const handleCopyAndUseSpider = (spiderInfo) => {
  confirmDeleteBox("将使用此版本的爬虫，确认使用吗？")
    .then(() => {
      onCopyAndUseSpider(spiderInfo);
    })
    .catch(() => {});
};
const onCopyAndUseSpider = async (spiderInfo) => {
  let res = await httpPost("/projectSpider/v1/copyAndUseSpiderVersion", {
    uid: spiderInfo.uid,
  });
  if (res.code == 0) {
    emits("refresh", "COPY");
    // ElMessage.success(`已为您使用${props.spiderVersionInfo.name}的爬虫`);
  }
};
const goSpiderDesign = (uid) => {
  window.open(
    spiderDesignHost + `/spider/design/index.html?projectSpiderUid=${uid}`
  );
};

onMounted(() => {
  spiderVersionRef.value.addEventListener(
    "focusout",
    async (e) => {
      const element = e.relatedTarget;
      const currentClickedId = element?.getAttribute("id");
      if (
        currentClickedId !== null &&
        (currentClickedId === spiderVersionNameRef.value.input?.id ||
          currentClickedId === spiderTextareaRef.value.textarea?.id)
      ) {
        return;
      }
      //保存
      await onSaveSpiderRecord(props.spiderVersionInfo.uid);
      closeEditor();
      tabIndex.value = null;
      emits("refresh");
    },
    false
  );
});
defineExpose({
  closeEditor,
});
</script>

<style lang="scss" scoped>
.version {
  margin-bottom: 20px;
  cursor: pointer;
  :deep(.el-card__header) {
    padding: 8px 12px;
  }
}
.active {
  border-color: $theme-color;
  :deep(.el-card__header) {
    border-bottom: 1px solid $theme-color;
  }
}
.card-header {
  display: flex;
  align-items: center;
  height: 20px;
  .card-left {
    /* width: 70px; */

    /* .spider-name-input {
      width: 100%;
    } */
  }
  .spider-name {
    color: #333333;
  }
  .version-editor {
    flex: 1;
  }
  .spider-status {
    margin-right: 10px;
  }
  .version-operation {
    width: 100%;
    height: 100%;
    display: none;
  }
  &:hover .version-operation {
    width: 60px;
    display: flex;
    gap: 10px;
    flex: 1;
    justify-content: flex-end;
  }
}

:deep(.el-card__body) {
  padding: 0;
}
.spider-content {
  padding: 12px 16px 20px 16px;
}
.spider-editor {
  padding: 12px 16px 16px 16px;
  min-height: 100px;
  :deep(.el-input) {
    min-height: 100px;
  }
  .textarea-editor {
    :deep(.el-textarea__inner) {
      resize: none;
    }
    :deep(.el-input__count) {
      height: 10px;
    }
  }
}
.spider-description {
  word-wrap: break-word;
  cursor: pointer;
  color: #6c6c6c;
}
.spider-info {
  display: flex;
  align-items: flex-end;
  /* padding-bottom: 10px; */
  margin-top: 20px;
  justify-content: space-between;
  .author,
  .time {
    color: #9a9a9a;
  }
  .copy-version-tip {
    color: $theme-color;
    cursor: pointer;
  }
}
:deep(.el-input) {
  --el-input-focus-border: #fff;
  --el-input-transparent-border: 0 0 0 0px;
  --el-input-border-color: #fff;
  --el-input-hover-border: 0px !important;
  --el-input-hover-border-color: #fff;
  --el-input-focus-border-color: #fff;
  --el-input-clear-hover-color: #fff;
  box-shadow: 0 0 0 0px !important;
  --el-input-border: 0px;
}
:deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 0px !important;
}
:deep(.el-select .el-input.is-focus .el-input__wrapper) {
  box-shadow: 0 0 0 0px !important;
}
</style>
