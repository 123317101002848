<template>
  <div class="table-view">
    <el-table
      :data="dataListTemp"
      v-loading="tableLoading"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <el-table-column type="selection" fixed width="55" />
      <el-table-column
        prop="taskNumber"
        label="任务ID"
        fixed
        width="130"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="url" label="源链接" fixed width="75">
        <template v-slot="scope">
          <i
            class="iconfont icon-url icon-lianjie1"
            @click="handleClickViewUrl(scope.$index, scope.row)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in dataColumnTemp"
        :key="index"
        :prop="item.name"
        :label="item.label"
        :formatter="formatRow"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="timestamp"
        fixed="right"
        label="采集时间"
        width="180"
      >
        <template #default="scope">
          <div>
            {{ scope.row.timestamp }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template #default="scope">
          <div class="list-item-btn">
            <div>
              <el-button
                link
                @click="handleClickOpenDetail(scope.row)"
                type="primary"
              >
                详情
              </el-button>
              <el-button
                link
                @click="handleClickDelete(scope.row)"
                type="primary"
              >
                删除
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <DataDrawerDetail ref="dataDrawerDetailRef" size="40%" />
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  nextTick,
  onMounted,
} from "vue";
import { usePagination } from "@/utils/usePagination";
import { timeFormatter } from "@/utils/time.js";
import DataDrawerDetail from "./DataDrawerDetail.vue";

const emits = defineEmits(["select-data", "delete-data"]);

const props = defineProps({
  dataColumn: {
    type: Array,
    default: () => [],
  },
  dataList: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

//表格数据
const dataColumnTemp = ref([]);
const dataListTemp = ref([]);
const tableLoading = ref(false);
watch(
  () => props.dataColumn,
  (newVal) => {
    dataColumnTemp.value = newVal;
  },
  {
    immediate: true,
    deep: true,
  }
);
watch(
  () => props.dataList,
  (newVal) => {
    dataListTemp.value = newVal;
  },
  {
    immediate: true,
    deep: true,
  }
);
watch(
  () => props.loading,
  (newVal) => {
    tableLoading.value = newVal;
  },
  {
    immediate: true,
  }
);

//多选操作
const handleSelectionChange = (val) => {
  emits("select-data", val);
};
const formatRow = (row, column) => {
  if (row[column.property] != null) {
    return row[column.property].length > 50
      ? row[column.property].substring(0, 50)
      : row[column.property];
  }
};
//删除数据
const handleClickDelete = (row) => {
  emits("delete-data", row);
};
//查看链接
const handleClickViewUrl = (index, row) => {
  window.open(row.url, "_blank");
};
//打开详情
const dataDrawerDetailRef = ref(null);
const handleClickOpenDetail = (row) => {
  dataDrawerDetailRef.value.openDWR({
    data: row,
    dataLabel: dataColumnTemp.value,
  });
};
</script>

<style lang="scss" scoped>
.icon-url {
  color: $theme-color;
  cursor: pointer;
}
</style>
