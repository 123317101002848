<template>
  <el-dialog
    width="35%"
    class="base-dialog"
    v-model="DLGVisible"
    :before-close="closeDLG"
    :title="DLGTitle"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formDataRef"
      label-width="100px"
    >
      <el-form-item label="字段名称：" prop="name">
        <el-input
          clearable
          placeholder="请输入字段名称"
          v-model.trim="formData.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="字段说明：" prop="label">
        <el-input
          clearable
          placeholder="请输入字段说明"
          v-model.trim="formData.label"
        ></el-input>
      </el-form-item>
      <el-form-item label="字段类型：" prop="type">
        <el-select v-model="formData.type" placeholder="请选择字段类型">
          <el-option
            v-for="(item, index) in FIELD_TYPE_OPTIONS"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="日期格式："
        prop="format"
        v-if="formData.type == 'datetime'"
      >
        <el-select v-model="formData.format" placeholder="请选择字段类型">
          <el-option label="yyyy-mm-dd" value="yyyy-mm-dd" />
          <el-option label="yyyy/mm/dd" value="yyyy/mm/dd" />
          <el-option label="yyyymmdd" value="yyyymmdd" />
        </el-select>
      </el-form-item>
      <el-form-item label="字段长度：" prop="size" v-else>
        <el-input
          clearable
          placeholder="请输入字段长度"
          v-model.trim="formData.size"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否必须：" prop="notNull">
        <el-switch v-model="formData.notNull" />
      </el-form-item>
      <el-form-item label="默认值：" prop="defaultValue">
        <el-input
          clearable
          placeholder="请输入默认值"
          v-model.trim="formData.defaultValue"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm('ruleForm')">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits, defineExpose, nextTick } from "vue";

import { httpPost } from "@/api/httpService.js";
import { Debounce } from "@/utils/utils";
import { remindErrorMessage } from "@/utils/utils";

import { FIELD_TYPE_OPTIONS } from "@/constant/field_type.js";

import { ElMessage } from "element-plus";

const emits = defineEmits(["refresh"]);
const dataTypeId = ref("");
const fieldDataList = ref("");
const DLGVisible = ref(false);
const DLGTitle = ref("新增字段");
const formDataRef = ref({});
const formData = ref({
  name: "",
  label: "",
  type: "",
  size: "",
  notNull: false,
  defaultValue: "",
  format: "",
});
const fieldList = ref([]);
// 编辑或新增类型
const addOrEditType = ref("");
const fieldIndex = ref();
//校验名称不能重复
const validateName = (rule, value, callback) => {
  value = value.trim();
  let rules = /^[a-z][_a-z]+$/;
  if (value === "") {
    callback(new Error("字段名称不能为空或空格"));
  } else if (!rules.test(value)) {
    callback(
      new Error("字段名称长度至少2位以字母开始，且只能是小写字母或下划线")
    );
  } else {
    if (fieldDataList.value.length > 0) {
      let isDuplicate = fieldDataList.value.some((item) => item.name === value);
      if (isDuplicate) {
        callback(new Error("字段名称不能重复"));
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
};
//校验规则
const rules = ref({
  name: [{ required: true, validator: validateName, trigger: "blur" }], // 字段名
  type: [{ required: true, message: "请选择字段类型", trigger: "change" }], // 说明
});

//添加字段
const handleClickConfirm = () => {
  formDataRef.value.validate(async (valid) => {
    if (valid) {
      let rule = /^[1-9]\d*$/;
      if (formData.value.type == "string") {
        if (!formData.value.size) {
          ElMessage({
            message: "字段类型为字符串时，字段长度必填",
            type: "error",
          });
          return false;
        } else {
          if (!rule.test(formData.value.size)) {
            ElMessage.warning("字段长度为正整数");
            return false;
          }
        }
      }
      formData.value.dataTypeId = dataTypeId.value;
      formData.value.notNull = formData.value.notNull ? 1 : 0;
      formData.value.size = formData.value.format ? "" : formData.value.size;
      if (addOrEditType.value === "ADD") {
        const ifRepeat = fieldList.value.some(
          (item) => item.name === formData.value.name
        );
        if (ifRepeat) {
          ElMessage.warning("字段名称重复");
          return;
        }
      } else {
        const ifRepeat = fieldList.value.some((item, index) => {
          if (index === fieldIndex.value) return false;
          return item.name === formData.value.name;
        });
        if (ifRepeat) {
          ElMessage.warning("字段名重复");
          return;
        }
      }
      addOrEditType.value === "ADD"
        ? ElMessage.success("添加成功")
        : ElMessage.success("修改成功");
      emits(
        "refresh",
        JSON.parse(JSON.stringify(formData.value)),
        addOrEditType.value,
        fieldIndex.value
      );
      closeDLG();
      // let params = { ...formData.value };
      // TODO 重新对接sjs-暂时搁置
      // let res = await httpPost("/setting/data/struct/save", params);

      // if (res.code == 0) {
      //   ElMessage.success("添加成功");
      //   closeDLG();
      //   emits("refresh");
      // } else {
      //   remindErrorMessage(res.message);
      // }
    }
  });
};
const openDLG = (type, data, id = "", fieldrowIndex = 0, row = {}) => {
  addOrEditType.value = type;
  fieldList.value = data;
  if (type == "ADD") {
    DLGTitle.value = "新增字段";
    // fieldDataList.value = data;
    dataTypeId.value = id;
  } else {
    DLGTitle.value = "编辑字段";
    formData.value = JSON.parse(JSON.stringify(row));
    fieldIndex.value = fieldrowIndex;
    formData.value.notNull == 1
      ? (formData.value.notNull = true)
      : (formData.value.notNull = false);
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// 重置表格
const resetForm = () => {
  formDataRef.value.resetFields();
  formData.value = {};
};
defineExpose({ openDLG, closeDLG });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/base-dialog.scss";
:deep(.el-input) {
  width: 260px;
}
</style>
