<template>
  <el-tag :color="color" :key="status">
    {{ title }}
  </el-tag>
</template>

<script setup>
import { ref, defineProps, computed } from "vue";
const props = defineProps({
  status: {
    type: Number,
  },
});
const title = "生效中";
const color = ref(`$theme - color `);
// const style = computed(() => {
//   const type = SPIDER_STATUS_MAP[props.status]?.type;
//   if (type) {
//     return null;
//   } else {
//     return `color: #1A78FF;border-color:#3894FF26`;
//   }
// });
</script>

<style lang="scss" scoped></style>
